a._link-white-wrapper, a._link-white-wrapper:hover, a._link-white-wrapper:visited,
a._link-white-wrapper:active{
    text-decoration: none;
    color: #fff;
}
.help-block {
    color: red;
    margin-top: 2px;
}
._black-page-errors {
    background: #000;
    border: 1px solid #fff;
}

.error-box._black-page-errors ul li {
    color: #fff;
}
._event {
    cursor: pointer;
    margin-bottom: 10px;
}
@media screen and (min-width: 450px)
{
    .r_contact-hours-box .time {
        margin-left: 5px;
    }
}
.drinks-box a {
    cursor: default;
}
._cell.today {
    background-color: white!important;
}
@media screen and (min-width: 998px) {
    .h51 {
        height: 51% !important;
    }
}
@media screen and (max-width: 450px) {
    .h51 .table-modal .modal-inner {
        padding: 60px 50px 50px;
    }
}