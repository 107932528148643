@charset 'UTF-8';

.red-btn,
.green-btn,
.gray-btn,
.white-btn,
.brown-btn
{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
}

.grid:after,
.clearfix:after,
.events-content:after,
.post:after,
.post .post-item:after,
.separate-offering:after,
.sip-post-info .body:after,
.tripple-grid:after,
.table-informer .informer:after,
.contact-separate-box:after,
.sign-up .fieldset-bottom:after,
.shopping-page .wine-club-item:after,
.shopping-page .gift-card-item:after,
.shopping-page .cart-gift-card .offering:after,
.order-fieldset.dual:after,
.our-wine-card .our-wine-item:after
{
    line-height: 0;

    display: block;
    visibility: hidden;
    clear: both;

    height: 0;

    content: '.';
}

html[xmlns] .grid,
html[xmlns] .clearfix,
html[xmlns] .events-content,
html[xmlns] .post,
html[xmlns] .post .post-item,
.post html[xmlns] .post-item,
html[xmlns] .separate-offering,
html[xmlns] .sip-post-info .body,
.sip-post-info html[xmlns] .body,
html[xmlns] .tripple-grid,
html[xmlns] .table-informer .informer,
.table-informer html[xmlns] .informer,
html[xmlns] .contact-separate-box,
html[xmlns] .sign-up .fieldset-bottom,
.sign-up html[xmlns] .fieldset-bottom,
html[xmlns] .shopping-page .wine-club-item,
.shopping-page html[xmlns] .wine-club-item,
html[xmlns] .shopping-page .gift-card-item,
.shopping-page html[xmlns] .gift-card-item,
html[xmlns] .shopping-page .cart-gift-card .offering,
.shopping-page .cart-gift-card html[xmlns] .offering,
html[xmlns] .order-fieldset.dual,
html[xmlns] .our-wine-card .our-wine-item,
.our-wine-card html[xmlns] .our-wine-item
{
    display: block;
}

* html .grid,
* html .clearfix,
* html .events-content,
* html .post,
* html .post .post-item,
.post * html .post-item,
* html .separate-offering,
* html .sip-post-info .body,
.sip-post-info * html .body,
* html .tripple-grid,
* html .table-informer .informer,
.table-informer * html .informer,
* html .contact-separate-box,
* html .sign-up .fieldset-bottom,
.sign-up * html .fieldset-bottom,
* html .shopping-page .wine-club-item,
.shopping-page * html .wine-club-item,
* html .shopping-page .gift-card-item,
.shopping-page * html .gift-card-item,
* html .shopping-page .cart-gift-card .offering,
.shopping-page .cart-gift-card * html .offering,
* html .order-fieldset.dual,
* html .our-wine-card .our-wine-item,
.our-wine-card * html .our-wine-item
{
    overflow: visible;

    height: 1%;
}

@font-face
{
    font-family: 'svg-fonts';

    src: url('../fonts/svg-fonts.eot');
    src: url('../fonts/svg-fonts.eot?#iefix') format('eot'), url('../fonts/svg-fonts.woff2') format('woff2'), url('../fonts/svg-fonts.woff') format('woff'), url('../fonts/svg-fonts.ttf') format('truetype'), url('../fonts/svg-fonts.svg#svg-fonts') format('svg');
}

.icon-arr-left:before,
.icon-arr-right:before,
.icon-check:before,
.icon-email:before,
.icon-left:before,
.icon-mail-flag:before,
.icon-phone:before,
.icon-print:before,
.icon-right:before,
.contact-box .phone .call2-icon:before,
.contact-box .mail .mail-icon:before,
.calendar-responsive .social-order .month-title .slide-left:before,
.calendar-responsive .social-order .month-title .slide-right:before,
.calendar-responsive .social-order .table-informer .call-icon:before,
.calendar-responsive .social-order .table-informer .print-icon:before,
.thank-you ._title [class*='-icon']:before
{
    font-family: 'svg-fonts';
    font-weight: normal;
    font-style: normal;
    font-variant: normal;

    text-decoration: none;
    text-transform: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arr-left:before
{
    content: '';
}

.icon-arr-right:before
{
    content: '';
}

.icon-check:before
{
    content: '';
}

.icon-email:before
{
    content: '';
}

.icon-left:before
{
    content: '';
}

.icon-mail-flag:before
{
    content: '';
}

.icon-phone:before
{
    content: '';
}

.icon-print:before
{
    content: '';
}

.icon-right:before
{
    content: '';
}

/* responsive.scss */

.g-container
{
    box-sizing: border-box;
    width: 100%;
    max-width: none;
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 20px;
}

.g-row
{
    display: flex;

    box-sizing: border-box;
    margin-right: -20px;
    margin-left: -20px;

    flex-wrap: wrap;
}

.g-col
{
    box-sizing: border-box;
    padding-right: 20px;
    padding-left: 20px;

    flex: 1;
}

.jssora05l,
.jssora05r
{
    position: absolute;

    display: block;
    overflow: hidden;
    /* size of arrow element */

    width: 40px;
    height: 40px;

    cursor: pointer;

    background: url(../img/slider-arrows.png) no-repeat;
}

.jssora05l
{
    background-position: -10px -40px;
}

.jssora05r
{
    background-position: -70px -40px;
}

.jssora05l:hover
{
    background-position: -130px -40px;
}

.jssora05r:hover
{
    background-position: -190px -40px;
}

.jssora05l.jssora05ldn
{
    background-position: -250px -40px;
}

.jssora05r.jssora05rdn
{
    background-position: -310px -40px;
}

.green-slider
{
    width: 100%;
    margin: 0 -59px;
    padding: 37px 59px;

    background: #a5cf4f;
}

.slider-image-style
{
    position: absolute;
    top: 0;

    overflow: hidden;

    width: 495px;
    height: 100%;

    border: 5px solid #fff;
    background-color: #fff;
    box-shadow: 0 0 0 1px #4f2d25;
}

img[u='image']
{
    width: 495px;
    height: 330px;
}

.slider-thumb-style
{
    position: absolute;
    top: 0;
    left: 100%;

    width: 495px;
    height: 100%;
    margin-left: 60px;
}

.slider-thumb
{
    position: absolute;

    width: 495px;
    height: 330px;
    margin-top: 6px;
}

.slider-thumb .p
{
    position: absolute;
    top: 0;
    left: 0;

    box-sizing: border-box;
    width: 145px;
    height: 95px;

    opacity: .7;
    border: 5px solid #fff;
    box-shadow: 0 0 0 1px #4f2d25;
}

.slider-thumb .p.pav
{
    opacity: 1;
}

.slider-thumb .t
{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border: none;
}

.slider-thumb .w
{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

.slider-thumb .c
{
    position: absolute;
    top: 0;
    left: 0;

    width: 135px;
    height: 85px;

    cursor: pointer;
}

.slider-thumb .pav .c
{
    width: 135px;
    height: 85px;
}

.slider-thumb .p:hover .c
{
    top: 0;
    left: 0;

    width: 135px;
    height: 85px;
}

.slider-thumb .p.pdn .c
{
    width: 135px;
    height: 85px;
}

* html .slider-thumb .c,
* html .slider-thumb .pdn .c,
* html .slider-thumb .pav .c
{
    width: 135px;
    height: 85px;
}

@font-face
{
    font-family: 'rock';

    src: url('../fonts/rockwell/Rockw.eot') format('eot'), url('../fonts/rockwell/Rockw.woff') format('woff'), url('../fonts/rockwell/Rockwell.otf') format('truetype');
}

@font-face
{
    font-family: 'script light';

    src: url('../fonts/script/YellowDesignStudio-ThirstyScriptLight.eot') format('eot'), url('../fonts/script/YellowDesignStudio-ThirstyScriptLight.woff') format('woff'), url('../fonts/script/YellowDesignStudio-ThirstyScriptLight.otf') format('truetype');
}

@font-face
{
    font-family: 'script';

    src: url('../fonts/script/YellowDesignStudio-ThirstyScriptMedium.eot') format('eot'), url('../fonts/script/YellowDesignStudio-ThirstyScriptMedium.woff') format('woff'), url('../fonts/script/ThirstyScriptMedium.otf') format('truetype');
}

@font-face
{
    font-family: 'clarendon';

    src: url('../fonts/clarendon/ClareTLig.eot') format('eot'), url('../fonts/clarendon/ClareTLig.woff') format('woff'), url('../fonts/clarendon/ClarendonTLight.otf') format('truetype');
}

@font-face
{
    font-family: 'clarendon bold';

    src: url('../fonts/clarendon/ClareTExtBol.eot') format('eot'), url('../fonts/clarendon/ClareTBol.woff') format('woff'), url('../fonts/clarendon/ClarendonTBold.otf') format('truetype');
}

@font-face
{
    font-family: 'clarendon medium';

    src: url('../fonts/clarendon/ClareTMed.eot') format('eot'), url('../fonts/clarendon/ClareTMed.woff') format('woff'), url('../fonts/clarendon/ClarendonTMedium.otf') format('truetype');
}

@font-face
{
    font-family: 'blair';

    src: url('../fonts/blair/BlairMdITCTT-Medium.eot') format('eot'), url('../fonts/blair/BlairMdITCTT-Medium.woff') format('woff'), url('../fonts/blair/BlairMdITCTT-Medium.otf') format('truetype');
}

@font-face
{
    font-family: 'SansSerif';

    src: url('../fonts/SansSerif/SansSerif.eot?#iefix') format('embedded-opentype'), url('../fonts/SansSerif/SansSerif.woff') format('woff'), url('../fonts/SansSerif/SansSerif.woff2') format('woff2'), url('../fonts/SansSerif/SansSerif.svg#str-replace("SansSerif", " ", "_")') format('svg');
}

@font-face
{
    font-family: 'SansSerifBold';

    src: url('../fonts/SansSerifBold/SansSerifBold.eot?#iefix') format('embedded-opentype'), url('../fonts/SansSerifBold/SansSerifBold.woff') format('woff'), url('../fonts/SansSerifBold/SansSerifBold.woff2') format('woff2'), url('../fonts/SansSerifBold/SansSerifBold.svg#str-replace("SansSerifBold", " ", "_")') format('svg');
}

@font-face
{
    font-family: 'SansSerifDemibold';

    src: url('../fonts/SansSerifDemibold/SansSerifDemibold.eot?#iefix') format('embedded-opentype'), url('../fonts/SansSerifDemibold/SansSerifDemibold.woff') format('woff'), url('../fonts/SansSerifDemibold/SansSerifDemibold.woff2') format('woff2'), url('../fonts/SansSerifDemibold/SansSerifDemibold.svg#str-replace("SansSerifDemibold", " ", "_")') format('svg');
}

@font-face
{
    font-family: 'SansSerifBook';

    src: url('../fonts/SansSerifBook/SansSerifBook.eot?#iefix') format('embedded-opentype'), url('../fonts/SansSerifBook/SansSerifBook.woff') format('woff'), url('../fonts/SansSerifBook/SansSerifBook.woff2') format('woff2'), url('../fonts/SansSerifBook/SansSerifBook.svg#str-replace("SansSerifBook", " ", "_")') format('svg');
}

.se_r
{
    font-family: 'SansSerif', Arial, sans-serif;
}

.se_b
{
    font-family: 'SansSerifBold', Arial, sans-serif;
}

.se_db
{
    font-family: 'SansSerifDemibold', Arial, sans-serif;
}

.se_bk
{
    font-family: 'SansSerifBook', Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5
{
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 1;

    text-transform: uppercase;
}

.t-left
{
    text-align: left;
}

.t-right
{
    text-align: right;
}

.t-center
{
    text-align: center;
}

.left
{
    float: left;
}

.right
{
    float: right;
}

.relative
{
    position: relative;
}

.order-fieldset ._payments-info .__label a,
.order-table ._edit a
{
    text-decoration: underline;
}

.order-fieldset ._payments-info .__label a:hover,
.order-table ._edit a:hover
{
    text-decoration: none;
}

.uppercase
{
    text-transform: uppercase;
}

.bold
{
    font-weight: 700;
}

.green
{
    color: #6ab81e;
}

.red
{
    color: #ed1b2e;
}

.violet
{
    color: #b33ecc;
}

.brown
{
    color: #bd2d28;
}

.blue
{
    color: #307db3;
}

img
{
    vertical-align: top;

    border-style: none;
}

a
{
    cursor: pointer;
    text-decoration: none;

    color: #4f2d25;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
}

a h1,
a h2,
a h3,
a h4,
a h5
{
    color: #4f2d25;
}

a:hover
{
    text-decoration: none;
}

input,
textarea,
select
{
    font-family: Arial, sans-serif;

    vertical-align: middle;

    color: #4f2d25;
}

form,
fieldset
{
    margin: 0;
    padding: 0;

    border-style: none;
}

textarea
{
    min-height: 150px;

    resize: none;
}

input[type='submit']:hover
{
    cursor: pointer;
}

input[type='submit']::-moz-focus-inner
{
    padding: 0;

    border: 0;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='file'],
input[type='tel'],
input[type='submit'],
textarea
{
    border-radius: 0;
    background: transparent;
    box-shadow: none;

    -webkit-appearance: none;
}

.i-settings,
input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
textarea
{
    font-size: 14px;
    line-height: 16px;

    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 4px 7px 6px;

    color: #4f2d25;
    border: 1px solid;
    border-color: #7c7c7c #c3c3c3 #ddd;
    outline: none;
    box-shadow: inset 0 1px 1px #ddd;
}

.i-settings:focus,
input:focus[type='text'],
input:focus[type='email'],
input:focus[type='password'],
input:focus[type='tel'],
textarea:focus,
.i-settings:active,
input:active[type='text'],
input:active[type='email'],
input:active[type='password'],
input:active[type='tel'],
textarea:active
{
    color: #4f2d25;
}

.error .i-settings,
.error input[type='text'],
.error input[type='email'],
.error input[type='password'],
.error input[type='tel'],
.error textarea
{
    color: #ed1b2e;
    border-color: #ed1b2e;
}

.error .i-settings::-webkit-input-placeholder,
.error input[type='text']::-webkit-input-placeholder,
.error input[type='email']::-webkit-input-placeholder,
.error input[type='password']::-webkit-input-placeholder,
.error input[type='tel']::-webkit-input-placeholder,
.error textarea::-webkit-input-placeholder
{
    color: #ed1b2e;
}

.error .i-settings:-moz-placeholder,
.error input:-moz-placeholder[type='text'],
.error input:-moz-placeholder[type='email'],
.error input:-moz-placeholder[type='password'],
.error input:-moz-placeholder[type='tel'],
.error textarea:-moz-placeholder
{
    color: #ed1b2e;
}

.error .i-settings::-moz-placeholder,
.error input[type='text']::-moz-placeholder,
.error input[type='email']::-moz-placeholder,
.error input[type='password']::-moz-placeholder,
.error input[type='tel']::-moz-placeholder,
.error textarea::-moz-placeholder
{
    color: #ed1b2e;
}

.error .i-settings:-ms-input-placeholder,
.error input:-ms-input-placeholder[type='text'],
.error input:-ms-input-placeholder[type='email'],
.error input:-ms-input-placeholder[type='password'],
.error input:-ms-input-placeholder[type='tel'],
.error textarea:-ms-input-placeholder
{
    color: #ed1b2e;
}

input[type='text'][required],
input[type='email'][required],
input[type='password'][required],
input[type='tel'][required],
textarea[required]
{
    border-color: #ed1b2e;
}

input[type='text'] ~ input,
input[type='text'] ~ textarea,
input[type='email'] ~ input,
input[type='email'] ~ textarea,
input[type='password'] ~ input,
input[type='password'] ~ textarea,
input[type='tel'] ~ input,
input[type='tel'] ~ textarea,
textarea ~ input,
textarea ~ textarea
{
    margin-top: 20px;
}

.red-btn,
.green-btn,
.gray-btn,
.white-btn,
.brown-btn
{
    font-family: 'clarendon medium';
    font-size: 16px;

    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    box-sizing: border-box;
    min-width: 40px;
    padding: 8px 22px 8px;

    transition: all .15s ease 0s;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;

    color: white;
    border: 1px solid transparent;
    box-shadow: inset 1px 1px 1px rgba(255, 255, 255, .4), inset -1px -1px 1px rgba(0, 0, 0, .15), 2px 2px 1px rgba(0, 0, 0, .2);

    *display: inline;
}

[class*='-btn'].fullsize
{
    width: 100%;
}

[class*='-btn']:hover
{
    opacity: .8;
}

[class*='-btn'].small
{
    font-size: 12px;

    padding: 5px 16px;
}

[class*='-btn'][class*='red-']
{
    color: #faf5e2;
    background-color: #ed1b2e;
}

[class*='-btn'][class*='brown-']
{
    background-color: #4f2d25;
}

[class*='-btn'][class*='green-']
{
    background-color: #8cc25c;
}

[class*='-btn'][class*='gray-']
{
    background-color: #909090;
}

[class*='-btn'][class*='white-']
{
    color: #ed1b2e;
    background-color: #fff;
    box-shadow: none;
}

[class*='-btn'] .calendar-icon,
[class*='-btn'] .wine-icon
{
    margin: -5px -15px -3px;
}

[class*='-btn'] .calendar-icon.small [class*='-btn'] .calendar-icon,
[class*='-btn'] .wine-icon.small [class*='-btn'] .calendar-icon,
[class*='-btn'] .calendar-icon.small [class*='-btn'] .wine-icon,
[class*='-btn'] .wine-icon.small [class*='-btn'] .wine-icon
{
    margin: -2px -8px;
}

.shadow-bottom
{
    box-shadow: 0 1px 14px rgba(0, 0, 0, .3), 0 0 40px rgba(0, 0, 0, .1) inset;
}

.shadow-bottom:before,
.shadow-bottom:after
{
    position: absolute;
    z-index: -1;
    top: 0;
    right: 10px;
    bottom: 0;
    left: 10px;

    content: '';

    border-radius: 100px / 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .8);
}

.shadow-bottom:after
{
    right: 10px;
    left: auto;

    transform: skew(8deg) rotate(3deg);
}

.warning
{
    color: #ed1b2e;
}

.warning input,
.warning textarea
{
    border-color: #ed1b2e;
}

[class*='-icon']
{
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    width: 0;
    height: 0;

    text-align: left;
    vertical-align: middle;
    text-indent: -9999px;

    background-image: url(../img/spr.png);
    background-repeat: no-repeat;
    background-position: -999px -999px;

    *display: inline;
}

[class*='-icon'].circle
{
    border-radius: 50%;
}

[class*='-icon'][class*='fb-nav-']
{
    width: 24px;
    height: 24px;

    background-position: 0 0;
}

[class*='-icon'][class*='basket-']
{
    width: 28px;
    height: 24px;

    background-position: -442px -422px;
}

[class*='-icon'][class*='quote-']
{
    width: 70px;
    height: 50px;

    background-position: -75px 0;
}

[class*='-icon'][class*='tw-nav-']
{
    width: 24px;
    height: 24px;

    background-position: -40px 0;
}

[class*='-icon'][class*='offering-option1-']
{
    width: 210px;
    height: 79px;

    background-position: 0 -63px;
}

[class*='-icon'][class*='offering-option2-']
{
    width: 210px;
    height: 79px;

    background-position: 0 -142px;
}

[class*='-icon'][class*='offering-option3-']
{
    width: 210px;
    height: 79px;

    background-position: 0 -221px;
}

[class*='-icon'][class*='special-event-']
{
    width: 330px;
    height: 124px;

    background-position: 0 -310px;
}

[class*='-icon'][class*='special-bottle-']
{
    width: 120px;
    height: 60px;

    background-position: -223px 0;
}

[class*='-icon'][class*='bottle-smile-']
{
    width: 84px;
    height: 175px;

    background-position: -230px -94px;
}

[class*='-icon'][class*='crossed-bottles-']
{
    width: 147px;
    height: 150px;

    background-position: -369px 0;
}

[class*='-icon'][class*='image-post1-']
{
    width: 48px;
    height: 65px;

    background-position: -359px -178px;
}

[class*='-icon'][class*='image-post2-']
{
    width: 56px;
    height: 58px;

    background-position: -452px -185px;
}

[class*='-icon'][class*='image-post3-']
{
    width: 49px;
    height: 49px;

    background-position: -366px -281px;
}

[class*='-icon'][class*='image-post4-']
{
    width: 69px;
    height: 46px;

    background-position: -447px -284px;
}

[class*='-icon'][class*='image-post5-']
{
    width: 51px;
    height: 50px;

    background-position: -359px -370px;
}

[class*='-icon'][class*='calendar-']
{
    width: 30px;
    height: 30px;

    background-position: -441px -370px;
}

[class*='-icon'][class*='dollar-']
{
    width: 32px;
    height: 32px;

    background-position: -484px -370px;
}

[class*='-icon'][class*='wine-']
{
    width: 32px;
    height: 32px;

    background-position: -537px -7px;
}

[class*='-icon'][class*='call-']
{
    width: 32px;
    height: 32px;

    background-position: -537px -60px;
}

[class*='-icon'][class*='call2-']
{
    width: 26px;
    height: 28px;

    background-position: -539px -234px;
}

[class*='-icon'][class*='mail-']
{
    width: 26px;
    height: 30px;

    background-position: -539px -277px;
}

[class*='-icon'][class*='location-']
{
    width: 26px;
    height: 32px;

    background-position: -539px -324px;
}

[class*='-icon'][class*='print-']
{
    width: 32px;
    height: 32px;

    background-position: -537px -112px;
}

[class*='-icon'][class*='close-modal-']
{
    width: 28px;
    height: 26px;

    background-position: -537px -178px;
}

[class*='-icon'][class*='thank-']
{
    width: 34px;
    height: 28px;

    background-position: -544px -422px;
}

[class*='-icon'][class*='slide-']
{
    width: 46px;
    height: 46px;
}

.slide-left [class*='-icon'][class*='slide-']
{
    background-position: -596px -62px;
}

.slide-right [class*='-icon'][class*='slide-']
{
    background-position: -596px 1px;
}

#hint
{
    font-size: 14px;

    position: absolute;
    z-index: 99;

    max-width: 210px;
    padding: 15px 20px;
    padding-top: 17px;
    padding-bottom: 17px;

    color: #fff;
    background-color: #535353;
}

#hint.fixed-position
{
    position: fixed;
}

#hint .hint-inner
{
    line-height: 18px;

    margin: -15px -20px;
    padding: 15px 20px;
}

#hint .hint-arrow-icon
{
    position: absolute;
    top: 100%;
    left: 10px;

    margin-top: -3px;
}

#hint.top-arrow-perspective [class*='hint-arrow']
{
    top: 100%;
}

#hint.bottom-arrow-perspective
{
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .5);
}

#hint.bottom-arrow-perspective [class*='hint-arrow']
{
    bottom: 100%;
}

#hint.bottom-arrow-perspective [class*='hint-arrow'][class*='-arrow-left-']
{
    border-width: 0 15px 20px 0;
}

#hint.bottom-arrow-perspective [class*='hint-arrow'][class*='-arrow-right-']
{
    border-width: 0 0 20px 15px;
}

#hint.bottom-arrow-perspective [class*='hint-arrow'][class*='-arrow-center-']
{
    border-width: 0 10px 20px 10px;
}

#hint [class*='hint-arrow']
{
    position: absolute;

    display: block;

    width: 0;
    height: 0;

    pointer-events: none;

    border-style: solid;
    border-color: #535353 transparent;
}

#hint .hint-arrow-left-icon
{
    left: 0;

    border-width: 20px 15px 0 0;
}

#hint .hint-arrow-right-icon
{
    right: 0;

    border-width: 20px 0 0 15px;
}

#hint .hint-arrow-center-icon
{
    right: 50%;

    margin-right: -10px;

    border-width: 20px 10px 0 10px;
}

.newListSelected
{
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    box-sizing: border-box;
    height: 38px;
    padding: 0 0 0 10px;

    cursor: pointer;
    vertical-align: middle;

    outline: 0;
    background: url(../img/select.png) 0 0 no-repeat;

    *display: inline;
}

.selectedTxt
{
    font: 100 16px/38px Arial,Helvetica,sans-serif;

    overflow: hidden;

    height: 38px;
    padding: 1px 40px 0 0;

    text-transform: capitalize;

    color: #000;
    background: white url(../img/select.png) 100% 0 no-repeat;
}

.newList
{
    position: absolute;
    z-index: 100;
    top: 22px;
    left: 0;

    overflow: auto;

    box-sizing: border-box;
    min-width: 100%;
    max-height: 200px;

    list-style: none;

    border: 1px solid #bdc1c2;
    background-color: #fff;
}

.newList *
{
    margin: 0;
    padding: 0;

    text-transform: lowercase;
}

.newList li
{
    padding: 0;

    list-style: none;

    outline: 0;
}

.newList li a
{
    font: 13px/16px Georgia,'Times New Roman',Times,serif;

    display: block;

    padding: 6px 8px 5px;

    text-transform: capitalize;

    color: #4575a1;
    outline: 0;
}

.newList li a:hover
{
    background-color: #f4f7fa;
}

.newList li a.hiLite
{
    background: #ebf3fa;
}

.grid .span
{
    float: left;
}

.grid.span2
{
    margin-left: -2%;
}

.grid.span2 .span
{
    width: 48%;
    margin-left: 2%;
}

.grid.span2 ~ .span2
{
    margin-top: 3%;
}

.grid.span3
{
    margin-left: -2%;
}

.grid.span3 .span
{
    width: 31%;
    margin-left: 2%;
}

.grid.span3 ~ .span3
{
    margin-top: 3%;
}

.grid.span5
{
    margin-left: -2%;
}

.grid.span5 .span
{
    width: 18%;
    margin-left: 2%;
}

.grid.span5 ~ .span5
{
    margin-top: 3%;
}

*
{
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
}

html,
body
{
    line-height: 1;

    margin: 0;
    padding: 0;

    color: #4f2d25;

    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body
{
    font-family: 'clarendon', Arial, sans-serif;
    font-size: 14px;

    background-color: #fff;
}

body a
{
    outline: none;
}

body.nav-opened
{
    overflow: hidden;
}

body.full-mode
{
    overflow: hidden;
}

body b,
body strong
{
    font-family: 'clarendon bold';
    font-weight: normal;
}

body pre
{
    font-family: inherit;
}

body select:active,
body select:focus
{
    outline: none;
}

*
{
    float: none;
}

.ellipsis
{
    display: inline-block;
    overflow: hidden;

    width: 100%;

    vertical-align: top;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ovhidden
{
    overflow: hidden;
}

#wrapper
{
    position: relative;

    overflow: hidden;

    box-sizing: border-box;
    min-height: calc(100vh - 66px);
}

.content-inner
{
    position: relative;

    min-width: 1000px;
    margin: 0 auto;
}

.content-inner
{
    box-sizing: border-box;
    padding: 20px;
}

.inner
{
    position: relative;

    box-sizing: border-box;
    min-width: 1000px;
    max-width: 1280px;
    margin: 0 auto;
}

section
{
    background-color: #fff;
}

.middle
{
    width: 100%;
    height: 100%;

    table-layout: fixed;
}

.middle td
{
    text-align: center;
    vertical-align: middle;
}

.header-nav
{
    display: flex;

    min-width: 0;
    padding-right: 20px;

    align-items: center;
    justify-content: space-around;
    flex: 1;
}

.header-nav .link
{
    white-space: nowrap;
}

.header-grid
{
    display: flex;

    padding-left: 320px;
}

header
{
    position: relative;
    z-index: 10;

    min-height: 60px;

    border-top: 6px solid #ab001a;
    background-color: #e61737;
}

header .inner
{
    min-width: 0;
}

header a
{
    font-family: 'rock';
    font-size: 16px;
    line-height: 17px;

    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    height: 17px;

    text-transform: uppercase;

    color: #fff;

    *display: inline;
}

header nav
{
    display: flex;

    height: 60px;

    text-align: right;
}

header nav a.link
{
    position: relative;

    padding: 10px 16px;
}

header nav a.link:hover,
header nav a.link.active
{
    background-color: #a70720;
}

header nav a.link:hover:before,
header nav a.link:hover:after,
header nav a.link.active:before,
header nav a.link.active:after
{
    position: absolute;

    display: none;

    width: 0;
    height: 0;

    content: '';

    border-style: solid;
}

header nav a.link:hover:before,
header nav a.link.active:before
{
    top: 0;
    left: -4px;

    border-width: 24px 0 0 5px;
    border-color: #a70720 #a70720 transparent transparent;
}

header nav a.link:hover:after,
header nav a.link.active:after
{
    top: 0;
    right: -6px;

    border-width: 0 6px 23px 0;
    border-color: transparent transparent #a70720;
}

header nav a.link-social
{
    display: flex;

    min-width: 50px;
    height: 100%;
    margin: 0;

    background-color: #ab001a;

    align-items: center;
    justify-content: center;
}

header nav a.link-social:first-child
{
    padding-left: 14px;
}

header nav a.link-social:last-child
{
    padding-right: 14px;
}

header nav a.link-social > img
{
    width: 24px;
}

header nav [class*='-icon']
{
    position: relative;
    top: -3px;
}

header .logotype
{
    position: absolute;
    top: -6px;
    left: -40px;

    display: inline-block;

    width: 230px;
    max-width: 50%;
}

header .logotype img
{
    max-width: 100%;
}

#main-slider
{
    position: relative;

    color: #fff;
}

#main-slider .image-box
{
    height: 100%;
}

#main-slider .image-box.big img
{
    height: 830px;
}

#main-slider .image-box img
{
    width: 100%;
    height: 360px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#main-slider .mask
{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    height: 100%;
}

#main-slider .mask table
{
    background: url(../img/slider-fade.png) 0 0 no-repeat;
    background-size: 100%;
}

#main-slider .title
{
    font-family: 'script light';
    font-size: 50px;
    line-height: 1.45;
}

#main-slider .subtitle
{
    font-family: 'blair';
    font-size: 32px;
    line-height: 45px;

    text-transform: uppercase;
}

#main-slider * + .title,
#main-slider * + .subtitle
{
    padding-top: 50px;
}

#main-slider .thumb
{
    margin-top: 50px;

    text-align: center;
}

#main-slider .thumb a
{
    font-size: 0;

    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    width: 13px;
    height: 13px;

    opacity: .5;
    border-radius: 100%;
    background: #fff;

    *display: inline;
}

#main-slider .thumb a.active
{
    opacity: 1;
}

#main-slider .thumb a + a
{
    margin-left: 8px;
}

#main-slider .slider-container
{
    margin: auto;
    padding: 0 50px;
}

#main-slider .slider-container__inner
{
    padding: 50px 0;

    border: solid #ddd;
    border-width: 1px 0;
}

.drinks-box
{
    position: relative;
}

.drinks-box .inner
{
    padding-top: 60px;
    padding-bottom: 60px;
}

.drinks-box .title
{
    font-family: 'script';
    font-size: 36px;

    text-align: center;

    color: #ed1b2e;
}

.drinks-box .subtitle
{
    font-size: 18px;
    line-height: 45px;

    padding-top: 25px;

    text-align: center;
}

.drinks-box .sort
{
    padding-top: 50px;

    text-align: center;
}

.drinks-box .sort .sort-image-box
{
    margin-top: 20px;
}

.drinks-box .sort a
{
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    width: 203px;

    vertical-align: top;

    *display: inline;
}

.drinks-box .sort a + a
{
    margin-left: 35px;
}

.drinks-box .sort a [class*=' sort_'] img
{
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: cover;
}

.drinks-box .sort a .spr-view > i
{
    transition: opacity .15s ease 0s;

    opacity: 1;
}

.drinks-box .sort a:hover .spr-view
{
    transition: background-color .2s ease-out;

    background-color: rgba(237, 27, 46, 0);
}

.drinks-box .sort a:hover .spr-view > i
{
    opacity: 0;
}

.drinks-box .sort a .spr-view
{
    border-radius: 50%;
    background-color: rgba(237, 27, 46, .7);
}

.drinks-box .sort a .sort_01 img
{
    background-image: url(../img/sorts/sort_01_bg.png);
}

.drinks-box .sort a .sort_02 img
{
    background-image: url(../img/sorts/sort_02_bg.png);
}

.drinks-box .sort a .sort_03 img
{
    background-image: url(../img/sorts/sort_03_bg.png);
}

.drinks-box .sort a .sort_04 img
{
    background-image: url(../img/sorts/sort_04_bg.png);
}

.drinks-box .sort a .sort_05 img
{
    background-image: url(../img/sorts/sort_05_bg.png);
}

.drinks-box .sort span
{
    font-family: 'script';
    font-size: 30px;

    display: block;

    color: #ed1b2e;
}

.drinks-box .sort img
{
    width: 100%;
}

.bouble-header
{
    position: relative;

    height: 9px;
    margin-top: -9px;

    background: url(../img/article-line.png) 50% 0 no-repeat;
}

.bouble-header img
{
    position: absolute;
    bottom: 0;
    left: 50%;

    margin-left: -80px;
}

.quote-box
{
    padding: 60px 0;

    text-align: center;

    color: #fff;
    background: url(../img/posters/poster_01.jpg) 0 0 no-repeat;
    background-size: cover;
}

.quote-box .inner
{
    position: relative;

    display: inline-block;

    box-sizing: border-box;
    min-width: 0;
    padding-right: 20px;
    padding-left: 20px;

    vertical-align: middle;
}

.quote
{
    font-family: 'clarendon';
    font-size: 18px;
    line-height: 24px;

    position: relative;

    box-sizing: border-box;
    padding-left: 40px;

    text-align: left;
}

.quote .quote-icon
{
    position: absolute;
    top: -14px;
    left: 0;
}

.quote-author
{
    font-size: 12px;
    line-height: 18px;

    margin-top: 20px;

    text-align: right;
}

.join-club
{
    margin-top: 115px;

    background: url(../img/join-bg.jpg) 0 0 no-repeat;
    background-size: cover;
}

.join-club .header
{
    position: relative;

    padding: 60px 0 30px;

    text-align: center;

    color: #fff;
    background: #bd2d28 url(../img/join-title-repeat.png) 0 0 repeat-x;
}

.join-club .header .title
{
    font-family: 'script';
    font-size: 36px;

    position: absolute;
    top: -81px;
    left: 50%;

    width: 445px;
    height: 122px;
    margin-left: -223px;
    padding-top: 56px;

    text-align: center;

    color: #fff;
    background: url(../img/join-title-bg.png) 0 0 no-repeat;
}

.join-club .header .subtitle
{
    font-family: 'ClarendonT-Bold';
    font-size: 18px;
    line-height: 22px;
}

.join-club .header .inner
{
    max-width: 955px;
}

.join-club .body
{
    padding: 60px 0 115px;

    text-align: center;
}

.offer
{
    position: relative;

    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    width: 300px;
    padding-top: 7px;
    padding-bottom: 40px;

    text-align: center;
    vertical-align: top;

    background-color: #fff;

    *display: inline;
}

.offer [class*='-icon']
{
    position: absolute;
    top: -25px;
    left: 0;
}

.offer .offering-box
{
    margin: 0 7px;
    padding: 40px 20px 20px;

    border: 1px solid #a3856e;
    background-color: #faf5e2;
}

.offer .offering-box .the
{
    font-family: 'script';
    font-size: 36px;

    text-transform: capitalize;

    color: #ed1b2e;
}

.offer .offering-box .title
{
    font-family: 'clarendon bold';
    font-size: 16px;
    line-height: 22px;

    text-transform: uppercase;

    background: none;
}

.offer .price
{
    font-family: 'script';
    font-size: 18px;
    line-height: 22px;

    padding-top: 35px;

    color: #ed1b2e;
}

.offer .price span
{
    font-size: 36px;
    line-height: 1;

    display: block;
}

.offer [class*='-btn']
{
    margin-top: 25px;
}

.offer + .offer
{
    margin-left: 40px;
}

.offering-description
{
    font-size: 18px;
    line-height: 20px;

    text-align: center;
}

.offering-description [class*='-btn']
{
    font-size: 30px;

    width: 345px;
}

.offering-description p + p
{
    padding-top: 20px;
}

.offering-description .divider
{
    font-size: 16px;
    line-height: 26px;

    color: #ed1b2e;
}

.offering-description .features
{
    font-family: 'clarendon bold';

    margin: 20px 0;
    padding: 5px;

    text-transform: uppercase;

    color: #ed1b2e;
    background-color: #faf5e2;
}

.special-events .inner
{
    padding: 70px 40px;
}

.special-events .title
{
    font-family: 'script light';
    font-size: 100px;

    text-align: center;

    color: #ed1b2e;
}

.special-events .thumb-image
{
    margin-top: 60px;
    margin-left: -17px;
}

.special-events .thumb-image a
{
    position: relative;

    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    margin-bottom: 20px;
    margin-left: 17px;
    padding: 4px;

    border: 1px solid #4f2d25;
    background-color: #fff;

    *display: inline;
}

.special-events .thumb-image a:hover
{
    opacity: .7;
}

.special-events .thumb-image a.active
{
    opacity: 1;
}

.special-events .thumb-image a.active:after
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    margin: 4px;

    content: '';

    background-color: rgba(133, 15, 11, .6);
}

.special-events .thumb-image a img
{
    display: block;

    width: 100%;
}

.events-content
{
    padding-top: 70px;
}

.events-content .main-image
{
    position: relative;

    float: left;

    width: 500px;
    padding: 4px;

    border: 1px solid #4f2d25;
    background-color: #fff;
}

.events-content .main-image img
{
    display: block;

    width: 100%;
}

.events-content .main-image .mask
{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    color: #fff;
    background-color: rgba(57, 57, 57, .57);
}

.events-content .main-image .mask .join
{
    font-family: 'clarendon bold';
    font-size: 16px;
    line-height: 20px;

    padding-top: 60px;

    text-transform: uppercase;
}

.events-content .main-image .mask .title
{
    font-family: 'script light';
    font-size: 75px;
    line-height: 100px;
}

.events-content .main-image > [class*='-icon']
{
    position: absolute;
    top: -25px;
    left: 0;
}

.events-content .main-image + .main-description
{
    padding-left: 570px;
}

.events-content .main-description
{
    font-size: 16px;
    line-height: 20px;
}

.events-content .main-description .title
{
    font-family: 'clarendon bold';
    font-size: 16px;
    line-height: 20px;

    text-align: left;
    text-transform: uppercase;

    color: #ed1b2e;
}

.events-content .main-description .title + p
{
    padding-top: 10px;
}

.events-content .main-description .title .date
{
    font-family: 'script light';
    font-size: 30px;

    padding-left: 30px;

    text-transform: capitalize;

    color: #4f2d25;
}

.events-content .main-description .title + .title
{
    margin-top: 20px;
}

.events-content .main-description p + p
{
    padding-top: 15px;
}

.events-content .main-description .md-item + .md-item
{
    margin-top: 40px;
}

.party-time
{
    padding-bottom: 70px;

    background: #faf5e2 url(../img/white-glass.png) 95% 100% no-repeat;
}

.party-time .main-description
{
    font-size: 14px;
    line-height: 18px;
}

footer
{
    font-family: 'clarendon bold';

    position: relative;
    bottom: 0;
    left: 0;

    width: 100%;

    background-color: #a2322c;
}

footer .space-between
{
    position: absolute;
    bottom: 32px;
    left: 50%;

    width: 130px;
    margin-left: -65px;

    white-space: nowrap;

    color: #fff;
}

footer .space-between .left-part
{
    position: absolute;
    right: 100%;
}

footer .space-between .right-part
{
    position: absolute;
    left: 100%;
}

footer .space-between a
{
    font-family: 'clarendon bold';
    font-size: 12px;

    padding: 0 2px;

    color: #fff;
}

footer .space-between a:hover
{
    opacity: .7;
}

footer .designed,
footer .rights
{
    font-size: 12px;
    line-height: 18px;

    position: absolute;
    bottom: 16px;

    color: rgba(255, 255, 255, .5);
}

footer .rights
{
    left: 20px;
}

footer .designed
{
    right: 20px;
}

footer .designed [href]
{
    color: inherit;
}

footer .designed [href]:hover
{
    text-decoration: underline;

    color: #4f2d25;
}

.post
{
    padding: 40px 0 60px;
}

.post .post-item
{
    overflow: hidden;

    padding: 15px 0;
}

.post .post-item.left-position
{
    float: left;

    text-align: left;
}

.post .post-item.left-position img
{
    margin-right: 55px;
    margin-left: -5px;
}

.post .post-item.left-position .inner
{
    padding-right: 40px;
}

.post .post-item.right-position
{
    float: right;

    text-align: right;
}

.post .post-item.right-position img
{
    margin-right: -5px;
    margin-left: 55px;
}

.post .post-item.right-position .inner
{
    padding-left: 40px;
}

.post .post-item p
{
    font-size: 18px;
    line-height: 45px;
}

.post .post-item p + p
{
    margin-top: 30px;
}

.post .post-item .inner
{
    max-width: 100%;
}

.post .post-item img.styled
{
    border: 4px solid #fff;
    box-shadow: 0 0 0 1px #4f2d25;
}

.post .post-item + .post-item
{
    margin-top: 40px;
}

.banner.bottle-offering
{
    font-size: 45px;
    line-height: 60px;

    height: 292px;

    color: #fff;
    background: url(../img/thumb/banner_01.jpg) 0 0 no-repeat;
    background-size: cover;
}

.banner.bottle-offering span
{
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    text-align: left;
    vertical-align: middle;

    *display: inline;
}

.banner.bottle-offering .inner > * + *
{
    margin-left: 40px;
}

.special-about-post
{
    padding: 15px 0;

    color: #fff;
    background: url(../img/about-post-bg.jpg) 0 0 no-repeat;
    background-size: cover;
}

.special-about-post td
{
    vertical-align: middle;
}

.special-about-post .title
{
    font-family: 'script light';
    font-size: 40px;

    padding-top: 20px;
    padding-bottom: 30px;
}

.special-about-post .subtitle
{
    font-family: 'blair';
    font-size: 18px;
    line-height: 25px;

    padding-bottom: 40px;
}

.special-about-post .author
{
    font-family: 'blair';
    font-size: 12px;
}

.special-self-post .title
{
    font-family: 'script';
    font-size: 48px;

    padding-bottom: 25px;

    color: #ed1b2e;
}

.special-self-post .post-item p
{
    font-size: 18px;
    line-height: 24px;
}

.special-self-post .product-visualize
{
    position: absolute;
    top: -11px;
    right: 95px;
}

.product-visualize
{
    line-height: 104px;

    width: 104px;
    height: 104px;
    padding-left: 8px;

    text-align: center;

    background: url(../img/image-mark-bg.png) 0 0 no-repeat;
}

.post-notification
{
    font-size: 18px;

    padding: 90px 40px;

    text-align: center;
}

.not-forget-self
{
    padding: 0 0 160px;
}

.not-forget-self .title
{
    font-family: 'script light';
    font-size: 100px;
    line-height: 100px;

    text-align: center;

    color: #ed1b2e;
}

.not-forget-self .subtitle
{
    font-family: 'blair';
    font-size: 32px;
    line-height: 45px;

    padding-top: 20px;

    text-align: center;
    text-transform: uppercase;

    color: #8e141f;
}

.not-forget-self .separate-offering
{
    padding-top: 60px;
}

.not-forget-self .inner
{
    padding: 0 40px;
}

.separate-offering
{
    font-size: 18px;
    line-height: 24px;
}

.separate-offering img
{
    box-sizing: border-box;
    width: 100%;

    border: 4px solid #fff;
    box-shadow: 0 0 0 1px #4f2d25;
}

.separate-offering p
{
    padding-top: 30px;
}

.separate-offering ul
{
    padding-top: 5px;
    padding-left: 25px;
}

.separate-offering ul li
{
    list-style-type: disc;
}

.separate-offering > div
{
    float: left;

    width: 47%;
}

.separate-offering > div + div
{
    margin-left: 6%;
}

.separate-offering .red-head
{
    padding: 15px;

    text-align: center;

    color: #fff;
    background-color: #ed1b2e;
    box-shadow: 0 0 0 1px #4f2d25;
}

.separate-offering .red-head .the
{
    font-family: 'script light';
    font-size: 48px;
    line-height: 1;
}

.separate-offering .red-head .red-title
{
    font-family: 'blair';
    font-size: 32px;
    line-height: 1;
}

.separate-offering .price
{
    font-family: 'script';
    font-size: 42px;
    line-height: 50px;

    margin-left: 7px;

    color: #ed1b2e;
}

.separate-offering .price span
{
    font-size: 22px;

    color: #b2323d;
}

.offering-price
{
    font-family: 'script';
    font-size: 42px;
    line-height: 90px;

    color: #ed1b2e;
}

.offering-price span
{
    font-size: 22px;

    color: #b2323d;
}

.sip-info
{
    font-size: 18px;
    line-height: 24px;

    text-align: center;

    color: #ed1b2e;
}

.sip-info .inner
{
    padding: 70px 0;
}

.sip-info .title
{
    font-family: 'blair';
    font-size: 32px;
    line-height: 45px;

    color: #8e141f;
}

.sip-info .title span
{
    color: #ed1b2e;
}

.sip-info .title + p
{
    padding-top: 20px;
}

.sip-post-info
{
    margin-bottom: 200px;

    border-top: 4px solid #000;
    background: url(../img/sip-post-bg.jpg) 0 0 no-repeat;
    background-size: cover;
}

.sip-post-info .title
{
    font-family: 'script light';
    font-size: 45px;

    color: #fff;
}

.sip-post-info .inner
{
    padding: 20px 40px;
}

.sip-post-info img
{
    max-width: 100%;
    margin-bottom: 15px;

    border: 4px solid #fff;
}

.sip-post-info .body
{
    font-size: 16px;
    line-height: 24px;

    padding: 20px 0;

    color: #fff;
}

.sip-post-info .left-panel
{
    float: left;

    width: 45%;

    text-align: center;
}

.sip-post-info .right-panel
{
    font-size: 22px;
    line-height: 30px;

    float: left;

    width: 55%;
}

.sip-post-info .right-panel p + p
{
    padding-top: 25px;
}

.our-wine-club
{
    padding: 70px 0;
}

.our-wine-club .title
{
    font-family: 'script light';
    font-size: 80px;
    line-height: 80px;

    text-align: center;

    color: #ed1b2e;
    background: url(../img/wine-title-bg.jpg) 50% 50% no-repeat;
}

.our-wine-club .title span
{
    padding: 0 20px;

    background-color: #fff;
}

.our-wine-club .subtitle
{
    font-size: 18px;
    line-height: 24px;

    max-width: 1050px;
    margin: auto;
    padding-top: 30px;

    text-align: center;
}

.our-wine-club .tripple-grid
{
    padding-top: 55px;
}

.our-wine-club .tripple-grid .col
{
    text-align: center;
}

.our-wine-club .bg
{
    padding-top: 40px;

    background: url(../img/join-bg.jpg) 0 0 no-repeat;
    background-size: cover;
}

.our-wine-club .indent
{
    margin: 0 15px;
}

.our-wine-club .offer .title
{
    color: #4f2d25;
}

.our-wine-club .offering-description
{
    min-height: 430px;
    padding: 30px;
}

.our-wine-club .fixed-type
{
    font-size: 30px;

    width: 345px;
}

.tripple-grid .col
{
    float: left;

    width: 33%;
}

.few-details
{
    font-size: 12px;
    line-height: 20px;

    position: relative;

    min-width: 570px;
    padding: 30px 30px 30px 400px;

    border: solid #a3856e;
    border-width: 1px 0;
    background-color: #faf5e2;
}

.few-details .left-panel
{
    font-family: 'script light';
    font-size: 45px;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 360px;

    text-align: center;

    color: #fff;
    background: url(../img/few-details-bg.jpg) 0 0 no-repeat;
    background-size: cover;
}

.few-details .right-panel
{
    max-width: 780px;
}

.few-details p
{
    position: relative;
}

.few-details p span
{
    position: absolute;
    top: 0;
    left: 0;

    margin-left: -8px;
}

.club-article
{
    font-size: 18px;
    line-height: 24px;

    position: relative;

    margin: 60px 0;
    padding-left: 440px;
}

.club-article img
{
    position: absolute;
    top: 0;
    left: 0;

    width: 375px;

    border: 4px solid #fff;
    box-shadow: 0 0 0 1px #4f2d25;
}

.club-article .club-title
{
    font-family: 'clarendon bold';
    font-size: 24px;

    margin-bottom: 30px;

    color: #ed1b2e;
}

.club-article .price
{
    font-family: 'script';
    font-size: 36px;

    padding-left: 20px;

    color: #b2323d;
}

.club-article .price span
{
    font-size: 18px;
}

.club-article p + p
{
    margin-top: 20px;
}

.club-title-divider
{
    position: relative;

    height: 42px;
}

.club-title-divider:before
{
    position: absolute;
    top: 50%;
    left: 0;

    box-sizing: border-box;
    width: 100%;
    height: 1px;

    content: '';

    background: #aaa;
}

.club-title-divider:after
{
    position: absolute;
    top: 0;
    left: 50%;

    box-sizing: border-box;
    width: 42px;
    height: 42px;

    content: '';
    transform: translateX(-21px);

    border: 1px solid #aaa;
    border-radius: 50%;
    background-color: #fff;
    background-image: url(../img/grey-glass.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
}

.social-order
{
    padding: 60px 30px;
}

.social-order .month-title
{
    font-family: 'script light';
    font-size: 55px;

    text-align: center;

    color: #ed1b2e;
}

.social-order .month-title a
{
    margin: 0 15px;
}

.social-order .month-title a:hover
{
    opacity: .7;
}

.table-informer
{
    box-sizing: border-box;
    min-width: 1000px;
    padding-top: 30px;
}

.table-informer .informer
{
    font-family: 'blair';
    font-size: 14px;

    padding-bottom: 30px;

    border-bottom: 1px solid #a3856e;
}

.table-informer .informer a
{
    text-align: center;

    color: #ed1b2e;
}

.table-informer .informer a span
{
    display: block;

    padding-top: 8px;
}

.table-informer .informer a:hover
{
    opacity: .7;
}

.table-informer .templates
{
    margin-top: 6px;
    padding: 14px 0;

    text-align: center;

    border-top: 1px solid #a3856e;
}

.table-informer .templates span
{
    font-family: 'blair';
    font-size: 24px;
}

.table-informer .templates span + span
{
    padding-left: 40px;
}

.table-informer .templates span .dot
{
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    width: 0;
    height: 0;

    vertical-align: middle;

    border: 6px solid;
    border-radius: 100%;

    *display: inline;
}

table.social
{
    width: 100%;

    table-layout: fixed;
}

table.social thead td
{
    font-family: 'blair';
    font-size: 32px;

    padding: 10px 10px 15px;

    text-align: center;

    color: #fff;
    border-width: 0;
    background-color: #ed1b2e;
    box-shadow: inset 1px -5px 0 #fff;
}

table.social td
{
    border: 1px solid #a3856e;
}

table.social ._cell
{
    font-family: 'Helvetica';
    font-size: 16px;
    line-height: 22px;

    position: relative;

    display: block;

    height: 180px;
    padding: 10px;

    background-color: #faf5e2;
}

table.social ._cell span
{
    display: block;
}

table.social ._cell ._d
{
    font-weight: bold;

    padding-bottom: 10px;

    color: #000;
}

table.social ._cell ._a
{
    font-weight: bold;
}

table.social ._cell ._h
{
    font-style: italic;
}

table.social ._cell ._closed
{
    font-family: 'clarendon';
    font-size: 16px;

    padding-top: 50px;

    text-align: center;
    text-transform: uppercase;

    color: #000;
}

table.social ._cell ._se
{
    font-weight: bold;

    padding-top: 10px;

    color: #6ab81e;
}

table.social ._cell:hover
{
    background-color: #fff;
}

.social-container
{
    box-sizing: border-box;
    min-width: 1000px;
    padding: 5px;

    border: 1px solid #a3856e;
}

.table-modal
{
    position: absolute;
    z-index: 99;

    width: 640px;

    border: 3px solid #ed1b2e;
    background-color: #fff;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, .2);
}

.table-modal .modal-inner
{
    padding: 80px 60px 60px;
}

.table-modal .close-modal
{
    font-family: 'Helvetica';
    font-size: 14px;
    font-weight: bold;

    position: absolute;
    top: 15px;
    right: 15px;

    text-transform: uppercase;

    color: #b0afaf;
}

.table-modal .close-modal i
{
    margin-left: 5px;
}

.table-modal .close-modal:hover i
{
    opacity: .7;
}

.table-modal .title
{
    font-family: 'clarendon bold';
    font-size: 24px;

    padding-bottom: 10px;

    color: #b2323d;
    border-bottom: 1px solid #b7b7b7;
}

.table-modal .content
{
    font-size: 14px;
    line-height: 20px;

    padding: 30px 0;
}

.table-modal .content p + p
{
    padding-top: 24px;
}

.table-modal .bottom-info
{
    font-family: 'clarendon bold';
    font-size: 24px;
    line-height: 30px;

    padding-top: 30px;

    text-align: center;

    color: #ed1b2e;
}

.private-events
{
    font-size: 18px;
    line-height: 35px;

    text-align: center;
}

.private-events .inner
{
    padding: 60px 40px;
}

.private-events .title
{
    font-family: 'blair';
    font-size: 32px;

    color: #ed1b2e;
}

.private-events .title + p
{
    margin-top: 40px;
}

.private-slider
{
    margin-bottom: 60px;
    padding: 60px;

    background: #bd2d28 url(../img/join-title-repeat.png) 0 0 repeat-x;
}

.private-slider .inner
{
    max-width: 1050px;
}

.private-articles
{
    padding: 70px 0;
}

.private-articles .inner
{
    max-width: 1180px;
    padding: 0 20px;
}

.private-articles .title
{
    font-family: 'script';
    font-size: 36px;

    margin-bottom: 10px;

    color: #ed1b2e;
}

.private-articles .title.brown
{
    font-family: 'blair';
    font-size: 32px;
    line-height: 36px;

    color: #8e141f;
}

.private-articles .article-images
{
    position: absolute;
    top: 0;
    left: 0;

    width: 355px;
}

.private-articles .article-images ~ .post-item
{
    padding-left: 435px;
}

.private-articles .article-images img
{
    width: 100%;

    border: 4px solid #fff;
    box-shadow: 0 0 0 1px #4f2d25;
}

.private-articles .article-images img + img
{
    margin-top: 150px;
}

.private-articles .post-item
{
    font-size: 16px;
    line-height: 20px;

    position: relative;
}

.private-articles .post-item ul
{
    margin-top: 10px;
}

.private-articles .post-item * + p,
.private-articles .post-item .features
{
    margin-top: 20px;
}

.private-articles .post-item .features
{
    font-family: 'clarendon bold';
    font-size: 18px;

    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    padding: 5px 8px;

    text-transform: uppercase;

    color: #ed1b2e;
    background-color: #faf5e2;

    *display: inline;
}

.private-articles .post-item ul
{
    padding-left: 25px;
}

.private-articles .post-item ul li
{
    font-size: 13px;

    list-style-type: disc;
}

.private-articles .post-item ul a
{
    font-size: 16px;

    text-decoration: underline;
}

.private-articles .post-item ul a:hover
{
    text-decoration: none;
}

.private-articles .post-item + .post-item
{
    margin-top: 50px;
}

.contact-separate-box
{
    border: solid #fff;
    border-width: 5px 0;
    background-color: #faf5e2;
    box-shadow: 0 0 0 1px #af9581;
}

.contact-separate-box > div
{
    float: left;

    width: 50%;
}

.contact-separate-box #map
{
    height: 360px;

    border-right: 5px solid #fff;
}

.contact-information
{
    min-width: 1000px;
    padding: 70px 0 100px;
}

.contact-information .title
{
    font-family: 'blair';
    font-size: 32px;

    color: #ed1b2e;
}

.contact-information .title + .content
{
    margin-top: 30px;
}

.contact-information .inner
{
    font-size: 18px;

    padding: 0 40px 60px;

    text-align: center;
}

.contact-box
{
    font-family: 'blair';
    font-size: 24px;

    position: relative;

    margin-top: 30px;

    color: #e61737;
}

.contact-box .c-title
{
    font-family: 'script light';
    font-size: 40px;

    margin-bottom: 50px;
}

.contact-box a
{
    color: #e61737;
}

.contact-box i
{
    margin-right: 10px;
}

.contact-information .contact-box
{
    max-width: 450px;
    margin: auto;
    padding: 50px 30px 0;
}

.contact-information .contact-box p
{
    line-height: 32px;

    position: relative;

    padding-left: 40px;
}

.contact-information .contact-box p + p
{
    margin-top: 18px;
}

.contact-information .contact-box p i
{
    position: absolute;
    top: 3px;
    left: 0;
}

.contact-box._center
{
    text-align: center;
}

.contact-box._center .label
{
    font-family: 'script';
    font-size: 45px;

    margin-right: 15px;

    text-transform: none;

    color: #ed1b2e;
}

.contact-box._center .label
{
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    *display: inline;
}

.contact-box._center [class*='-btn']
{
    font-size: 30px;

    margin: 0 0 0 15px;
}

.contact-box .note
{
    font-family: 'clarendon';
    font-size: 14px;

    position: absolute;
    top: 100%;
    right: 0;
    left: 0;

    margin-top: 25px;

    text-align: center;

    color: #ed1b2e;
}

.contact-box .phone
{
    display: inline-flex;

    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.contact-box .phone .call2-icon
{
    font-family: 'svg-fonts';
    font-size: 0;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;

    position: relative;

    display: block;

    width: 26px;
    min-width: 26px;
    height: 26px;
    margin-top: 4px;
    margin-bottom: 4px;

    text-decoration: none;
    text-indent: 0;
    text-transform: none;

    color: transparent;
    border-radius: 50%;
    background: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.contact-box .phone .call2-icon:before
{
    content: '';
}

.contact-box .phone .call2-icon:before
{
    font-size: 26px;

    position: absolute;
    top: 50%;
    left: 50%;

    display: block;

    transform: translate(-50%, -50%);

    color: #770914;
}

.contact-box .mail
{
    display: inline-flex;

    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.contact-box .mail .mail-icon
{
    font-family: 'svg-fonts';
    font-size: 0;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;

    position: relative;

    display: block;

    width: 26px;
    min-width: 26px;
    height: 26px;
    margin-top: 4px;
    margin-bottom: 4px;

    text-decoration: none;
    text-indent: 0;
    text-transform: none;

    color: transparent;
    border-radius: 50%;
    background: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.contact-box .mail .mail-icon:before
{
    content: '';
}

.contact-box .mail .mail-icon:before
{
    font-size: 26px;

    position: absolute;
    top: 50%;
    left: 50%;

    display: block;

    transform: translate(-50%, -50%);

    color: #770914;
}

.modal-fade
{
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, .7);
}

.modal-fade table td
{
    padding: 40px;
}

.modal-window
{
    position: relative;
    z-index: 99;

    width: 640px;
    margin: auto;

    border: 3px solid #ed1b2e;
    background-color: #fff;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, .2);
}

.modal-window .modal-inner
{
    padding: 70px 50px 50px;
}

.modal-window .close-modal
{
    font-family: 'Helvetica';
    font-size: 14px;
    font-weight: bold;

    position: absolute;
    top: 15px;
    right: 15px;

    text-transform: uppercase;

    color: #b0afaf;
}

.modal-window .close-modal i
{
    margin-left: 5px;
}

.modal-window .close-modal:hover i
{
    opacity: .7;
}

.modal-window .title
{
    font-family: 'script';
    font-size: 45px;

    color: #ed1b2e;
}

.modal-window p
{
    font-size: 16px;
    line-height: 24px;
}

.modal-window .club-title-divider
{
    margin: 20px 0;
}

.sign-up
{
    padding-top: 30px;
}

.sign-up .field-item
{
    position: relative;

    min-height: 27px;
    padding-left: 135px;

    text-align: left;
}

.sign-up .field-item select
{
    min-width: 140px;
    height: 27px;
}

.sign-up .field-item input[type='text']
{
    width: 300px;
}

.sign-up .field-item label
{
    line-height: 26px;

    position: absolute;
    left: 0;

    width: 115px;

    text-align: right;
}

.sign-up .field-item + .field-item
{
    margin-top: 14px;
}

.sign-up .fieldset-bottom
{
    margin-top: 30px;
    padding-top: 20px;

    text-align: left;

    border-top: 1px solid #b7b7b7;
}

.sort-image-box
{
    position: relative;

    display: block;
}

.sort-image-box .spr-view
{
    line-height: 203px;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    text-align: center;
}

.inform
{
    font-size: 14px;
}

.calendar-responsive
{
    padding-bottom: 50px;
}

.calendar-responsive,
.calendar-responsive *
{
    box-sizing: border-box;
}

.calendar-responsive #main-slider .inner
{
    min-width: initial;
}

.calendar-responsive .social-order .month-title
{
    margin-bottom: -50px;
}

.calendar-responsive .social-order .month-title > span
{
    display: inline-flex;

    vertical-align: top;

    align-items: center;
}

.calendar-responsive .social-order .month-title .slide-left,
.calendar-responsive .social-order .month-title .slide-right
{
    font-family: 'svg-fonts';
    font-weight: normal;
    font-style: normal;
    font-variant: normal;

    position: relative;

    display: block;

    width: 46px;
    min-width: 46px;
    height: 46px;

    text-decoration: none;
    text-transform: none;

    border: 3px solid #ed1b2e;
    border-radius: 50%;
    background: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.calendar-responsive .social-order .month-title .slide-left:before,
.calendar-responsive .social-order .month-title .slide-right:before
{
    font-size: 18px;

    position: absolute;
    top: 50%;
    left: 50%;

    display: block;

    transform: translate(-50%, -50%);

    color: #ed1b2e;
}

.calendar-responsive .social-order .month-title .slide-left
{
    margin-right: 30px;
}

.calendar-responsive .social-order .month-title .slide-left:before
{
    content: '';
}

.calendar-responsive .social-order .month-title .slide-left:before
{
    margin-left: -1px;
}

.calendar-responsive .social-order .month-title .slide-right
{
    margin-left: 30px;
}

.calendar-responsive .social-order .month-title .slide-right:before
{
    content: '';
}

.calendar-responsive .social-order .month-title .slide-right:before
{
    margin-left: 1px;
}

.calendar-responsive .social-order .month-title .slide-icon
{
    display: none;
}

.calendar-responsive .social-order .table-informer
{
    min-width: initial;
    padding-top: 0;
}

.calendar-responsive .social-order .table-informer .informer
{
    padding-bottom: 50px;
}

.calendar-responsive .social-order .table-informer .call-icon,
.calendar-responsive .social-order .table-informer .print-icon
{
    font-family: 'svg-fonts';
    font-size: 0;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;

    width: auto;
    height: auto;

    text-decoration: none;
    text-indent: 0;
    text-transform: none;

    color: transparent;
    background: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.calendar-responsive .social-order .table-informer .call-icon:before,
.calendar-responsive .social-order .table-informer .print-icon:before
{
    font-size: 26px;

    display: block;

    color: #ed1b2e;
}

.calendar-responsive .social-order .table-informer .call-icon:before
{
    content: '';
}

.calendar-responsive .social-order .table-informer .print-icon:before
{
    content: '';
}

.calendar-responsive .social-order .social-container
{
    min-width: initial;
}

.calendar-responsive .social-order table.social ._cell
{
    font-size: 14px;
    line-height: 18px;

    height: 150px;
}

.calendar-responsive .social-order table.social ._cell ._d span
{
    display: none;
}

.calendar-responsive .social-order table.social ._cell ._wrap
{
    font-family: 'clarendon';
}

.calendar-responsive .social-order table.social ._cell ._h
{
    font-style: normal;
}

.calendar-responsive .table-modal
{
    width: 650px;
    max-width: calc(100% - 40px);
}

.calendar-responsive .table-modal .bottom-info span,
.calendar-responsive .table-modal .bottom-info a
{
    font-size: 24px;
    line-height: 28px;

    display: inline-block;

    margin-top: 10px;

    text-decoration: none;

    color: inherit;
}

.table-modal__positioner
{
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;

    display: flex;
    overflow-y: auto;

    width: 100%;
    height: 100%;

    pointer-events: none;

    align-items: center;
    justify-content: center;
}

.table-modal__positioner .table-modal
{
    position: relative;

    overflow: hidden;

    box-sizing: border-box;
    max-width: calc(100% - 40px);
    height: auto;
    margin: 20px;

    pointer-events: all;
}

.shopping-page
{
    padding-top: 95px;
    padding-bottom: 120px;
}

.shopping-page .title
{
    font-family: 'script light';
    font-size: 70px;

    text-align: center;

    color: #ed1b2e;

    font-variant-ligatures: none;
}

.shopping-page .title + .subtitle
{
    padding-top: 40px;
}

.shopping-page .subtitle
{
    font-size: 18px;
    line-height: 1.5;

    max-width: 680px;
    margin: auto;
    padding-right: 24px;
    padding-left: 24px;

    text-align: center;
}

.shopping-page .contact-box
{
    display: flex;
    flex-direction: column;

    margin-top: 50px;

    text-align: center;

    justify-content: center;
}

.shopping-page .contact-box .label
{
    font-family: 'script';
    font-size: 45px;

    margin: 0 0 15px;

    text-transform: none;

    color: #ed1b2e;
}

.shopping-page .contact-box .label
{
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    *display: inline;
}

.shopping-page .contact-box [class*='-btn']
{
    font-size: 30px;

    margin: 0 0 0 15px;
}

.shopping-page .bg
{
    width: 385px;
    padding-top: 40px;

    text-align: center;
}

.shopping-page .wine-club-item
{
    max-width: 940px;
    margin: auto;
    padding: 8px 0;

    border: solid #ed1b2e;
    border-width: 1px 0;
}

.shopping-page .wine-club-item + .wine-club-item
{
    margin-top: 30px;
}

.shopping-page .wine-club-item .bg
{
    float: left;
}

.shopping-page .wine-club-item .bg + .content
{
    margin-left: 385px;
}

.shopping-page .wine-club-item .c_title
{
    font-size: 18px;
}

.shopping-page .wine-club-item .content
{
    font-size: 16px;
    line-height: 20px;

    width: 350px;
    padding: 40px;
}

.shopping-page .wine-club-item .content p + p
{
    margin-top: 22px;
}

.shopping-page .gift-card-item
{
    max-width: 940px;
    margin: auto;
    padding: 23px 0;

    border: solid #ed1b2e;
    border-width: 1px 0;
}

.shopping-page .gift-card-item .c_title
{
    font-family: 'clarendon bold';
    font-size: 24px;

    padding-top: 25px;

    color: #ed1b2e;
}

.shopping-page .gift-card-item .c_title + .c_subtitle
{
    margin-top: 5px;
}

.shopping-page .gift-card-item img
{
    float: left;

    width: 385px;

    border: 5px solid #fff;
    box-shadow: 0 0 0 1px #4f2d25;
}

.shopping-page .gift-card-item img + .content
{
    margin-left: 385px;
}

.shopping-page .gift-card-item .content
{
    font-size: 16px;
    line-height: 20px;

    padding: 20px 0 20px 40px;

    color: #4f2d25;
}

.shopping-page .gift-card-item .content p + p
{
    margin-top: 25px;
}

.shopping-page .gift-card-item .content .price
{
    margin-top: 35px;
}

.shopping-page .gift-card-item .price
{
    font-family: 'script';
    font-size: 36px;
    line-height: 1;

    color: #b2323d;
}

.shopping-page .gift-card-item .price span
{
    font-size: 18px;
}

.shopping-page .gift-card-item .tools
{
    font-family: 'clarendon bold';
    font-size: 18px;

    margin-top: 60px;

    color: #4f2d25;
}

.shopping-page .gift-card-item .tools .newListSelected,
.shopping-page .gift-card-item .tools [class*='-btn']
{
    margin-left: 15px;
}

.shopping-page .gift-card-item .bottom-info
{
    font-size: 14px;
}

.shopping-page .cart-gift-card
{
    margin-top: 40px;
}

.shopping-page .cart-gift-card .gift-card-item
{
    padding-top: 36px;

    border-bottom: none;
}

.shopping-page .cart-gift-card .gift-card-item img
{
    border: none;
}

.shopping-page .cart-gift-card .content
{
    line-height: 24px;

    padding-top: 0;
}

.shopping-page .cart-gift-card .content p + p
{
    margin-top: 20px;
}

.shopping-page .cart-gift-card .offering
{
    max-width: 940px;
    margin: auto;
}

.shopping-page .cart-gift-card .offering .span
{
    border: solid #ed1b2e;
    border-width: 1px 0;
}

.shopping-page .cart-gift-card .offering .span3
{
    margin-left: -7%;
}

.shopping-page .cart-gift-card .offering .span3 .span
{
    width: 26%;
    margin-left: 7%;
}

.shopping-page .cart-gift-card .offering .cells
{
    text-align: center;
}

.shopping-page .cart-gift-card .offering .cells .span
{
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    width: 26%;

    text-align: left;
    vertical-align: top;

    *display: inline;
}

.shopping-page .cart-gift-card .offering .cells .span + .span
{
    margin-left: 7%;
}

.shopping-page .cart-gift-card .offering table
{
    font-family: 'clarendon bold';
    font-size: 20px;

    width: 100%;
    margin: 5px 0;

    table-layout: fixed;
    border-collapse: collapse;

    text-align: right;

    color: #ed1b2e;
}

.shopping-page .cart-gift-card .offering table .odd
{
    background-color: #fbf9f9;
}

.shopping-page .cart-gift-card .offering table td
{
    padding: 8px 15px;

    vertical-align: middle;
}

.shopping-page .cart-gift-card .offering .newListSelected,
.shopping-page .cart-gift-card .offering [class*='-btn']
{
    margin-left: 15px;
}

.page-subtitle
{
    font-family: 'clarendon bold';
    font-size: 24px;

    padding: 50px 0;

    text-align: center;

    color: #ed1b2e;
}

.shopping-page-responsive,
.shopping-page-responsive *
{
    box-sizing: border-box;
}

.shopping-page-responsive .inner
{
    min-width: initial;
}

.shopping-page-responsive .contact-box
{
    display: flex;

    margin-bottom: 40px;
    padding: 20px 0;

    border: solid #ed1b2e;
    border-width: 1px 0;

    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.shopping-page-responsive .contact-box.contact-box--bottom
{
    margin-top: 40px;
}

.shopping-page-responsive .cart-gift-card .gift-card-item .content.content--top
{
    margin-bottom: 30px;

    text-align: center;
}

.shopping-page-responsive .cart-gift-card .gift-card-item .content.content--top .c_title
{
    margin-top: 0;
    margin-bottom: 10px;
    padding-top: 0;
}

.shopping-page-responsive .newListSelected
{
    min-width: 74px;
}

.shopping-page-responsive .newListSelected .newList
{
    height: auto !important;
    margin: 0;
    padding: 0;
}

.order-form
{
    max-width: 1050px;
    margin: auto;
    padding-top: 95px;
    padding-bottom: 120px;
}

.order-form .title
{
    font-family: 'script light';
    font-size: 100px;

    text-align: center;

    color: #ed1b2e;
}

.order-form .subtitle
{
    font-size: 18px;
    line-height: 45px;

    padding-top: 25px;

    text-align: center;
}

.order-form .contact-box
{
    margin-bottom: 70px;
    padding: 20px 0;

    border: solid #ed1b2e;
    border-width: 1px 0;
}

.form-message
{
    font-size: 14px;
    line-height: 16px;

    margin-bottom: 50px;

    text-align: center;
}

.form-message.error .message-text
{
    color: #ed1b2e;
    border: 1px solid #ed1b2e;
    background-color: #ffebec;
}

.form-message .message-text
{
    font-family: 'clarendon medium';

    display: inline-block;

    padding: 6px 8px;

    text-align: left;
}

.order-steps
{
    margin: 48px 0;
}

.order-steps table
{
    width: 100%;
}

.order-steps td
{
    padding: 20px 20px 20px 0;
}

.order-steps td + td
{
    padding-left: 20px;

    border-left: 1px solid #cbcbcb;
}

.order-steps ._step
{
    font-family: 'clarendon';
    font-size: 30px;

    position: relative;

    padding-left: 60px;

    opacity: .3;
    color: #737373;
}

.order-steps ._n
{
    font-family: 'clarendon medium';
    font-size: 30px;
    line-height: 30px;

    position: absolute;
    top: 0;
    left: 0;

    box-sizing: border-box;
    width: 40px;
    height: 40px;

    text-align: center;

    color: #737373;
    border: 3px solid #737373;
    border-radius: 20px;
}

.order-steps .active
{
    opacity: 1;
    color: #8e141f;
}

.order-steps .active ._n
{
    color: #fff;
    border-color: #e61737;
    background-color: #e61737;
}

.order-fieldset-info
{
    padding: 0 0 35px 135px;

    color: #ed1b2e;
}

.order-fieldset .order-fieldset-info
{
    padding: 35px 0 0 135px;
}

.order-fieldset-info .important
{
    position: relative;

    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    width: 16px;
    height: 16px;

    vertical-align: middle;

    *display: inline;
}

.order-fieldset-info .important:before
{
    font-family: 'clarendon medium';
    font-size: 30px;

    position: absolute;
    top: -5px;
    right: 5px;

    content: '*';

    color: #ed1b2e;
}

.order-fieldset
{
    font-family: 'clarendon';

    position: relative;

    padding-bottom: 35px;

    color: #4f2d25;
}

.order-fieldset.dual ._left
{
    float: left;
}

.order-fieldset.dual ._right
{
    float: right;
}

.order-fieldset.dual > div
{
    width: 43%;
}

.order-fieldset.dual fieldset
{
    width: 100%;
}

.order-fieldset ._title span
{
    font-family: 'rock';
    font-size: 18px;
    line-height: 50px;

    display: block;

    padding: 0 20px;

    letter-spacing: 2px;
    text-transform: uppercase;

    color: #fff;
    background-color: #ed1b2e;
}

.order-fieldset ._subtitle
{
    font-family: 'clarendon medium';
    font-size: 14px;

    position: relative;

    margin: 20px 20px 30px;
}

.order-fieldset ._subtitle.fixed
{
    height: 50px;
}

.order-fieldset fieldset
{
    width: 43%;
}

.order-fieldset ._fielditem
{
    position: relative;

    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    padding-right: 28px;
    padding-left: 135px;
}

.order-fieldset ._fielditem + ._fielditem
{
    margin-top: 6px;
}

.order-fieldset ._fielditem.error.important:after
{
    top: 16px;
}

.order-fieldset ._fielditem.error .message
{
    display: block;

    color: #ed1b2e;
}

.order-fieldset ._fielditem.error .message ~ label
{
    top: 16px;
}

.order-fieldset ._fielditem.error label
{
    color: #ed1b2e;
}

.order-fieldset ._fielditem.error input[type='text']
{
    border-color: #ed1b2e;
    background-color: #ffebec;
}

.order-fieldset ._fielditem .message
{
    font-size: 14px;
    line-height: 14px;

    display: none;

    margin-bottom: 2px;
}

.order-fieldset ._fielditem > label
{
    line-height: 24px;

    position: absolute;
    top: 4px;
    right: 100%;

    margin-right: -110px;

    text-align: right;
    white-space: nowrap;
}

.order-fieldset ._fielditem [type='text']
{
    font-size: 13px;
    line-height: 24px;

    padding: 3px 4px;

    border-color: #bbb;
}

.order-fieldset ._fielditem select
{
    font-size: 13px;
    line-height: 16px;

    box-sizing: border-box;
    width: 100%;
    padding: 3px 4px;

    border: 1px solid #bbb;
    border-radius: 0;
    outline: none;
    background: transparent;
    box-shadow: inset 0 1px 1px #ddd;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.order-fieldset ._fielditem .select-wrap
{
    position: relative;
}

.order-fieldset ._fielditem .select-wrap:before
{
    position: absolute;
    top: 50%;
    right: 6px;

    display: block;

    content: '';
    transform: translateY(-50%);

    border: 4px solid transparent;
    border-top: 5px solid rgba(79, 45, 37, .5);
    border-bottom: none;
}

.order-fieldset ._fielditem.dual
{
    display: flex;
}

.order-fieldset ._fielditem.dual select,
.order-fieldset ._fielditem.dual [type='text']
{
    width: 48%;
}

.order-fieldset ._fielditem.dual select + select,
.order-fieldset ._fielditem.dual [type='text'] + [type='text']
{
    margin-left: 4%;
}

.order-fieldset ._fielditem.dual .select-wrap
{
    width: 48%;
}

.order-fieldset ._fielditem.dual .select-wrap select
{
    width: 100%;
    margin: 0;
}

.order-fieldset ._fielditem.dual .select-wrap + .select-wrap
{
    margin-left: 4%;
}

.order-fieldset ._fielditem [class*='-btn']
{
    position: absolute;
    top: -7px;
    left: 100%;
}

.order-fieldset ._fielditem._radio
{
    line-height: 26px;

    margin-top: 30px;

    white-space: nowrap;
}

.order-fieldset ._fielditem._radio label + label
{
    margin-left: 10px;
}

.order-fieldset .check-the-field
{
    position: absolute;
    bottom: 0;
    left: 0;

    white-space: normal;
}

.order-fieldset .check-the-field [type='checkbox']
{
    margin-right: 10px;
}

.order-fieldset ._payments-info
{
    font-size: 14px;
    line-height: 18px;

    position: relative;

    padding: 20px 350px 0 0;

    color: #e61737;
}

.order-fieldset ._payments-info .__label
{
    font-family: 'clarendon medium';

    padding-bottom: 10px;

    color: #8e141f;
}

.order-fieldset ._payments-info .__label a
{
    color: #8e141f;
}

.order-fieldset ._payments-info .payments-total
{
    position: absolute;
    top: 0;
    right: 0;
}

.order-fieldset-bottom
{
    padding-top: 30px;

    text-align: right;

    border-top: 1px solid #ed1b2e;
}

.order-fieldset-bottom [class*='btn']
{
    font-family: 'clarendon';
    font-size: 25px;
}

.order-fieldset-bottom ._total
{
    position: relative;

    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    *display: inline;
}

.order-fieldset-bottom ._total ._verified
{
    position: absolute;
    top: 50%;
    right: 100%;

    margin-top: -50px;
    margin-right: 40px;
}

.order-fieldset-bottom ._total .divider
{
    font-family: 'clarendon medium';
    font-size: 30px;
    line-height: 56px;

    height: 56px;
    padding-right: 110px;

    text-align: right;

    color: #4f2d25;
}

.order-fieldset-bottom ._total .button-notice
{
    font-family: 'clarendon';
    font-size: 12px;
    line-height: 16px;

    position: absolute;
    top: 8px;
    right: 100%;

    margin-right: 25px;

    text-align: right;
    white-space: nowrap;

    color: #9b9b9b;
}

.order-fieldset-bottom ._total .gray-btn
{
    font-size: 16px;

    padding: 12px;
}

.order-fieldset-bottom ._total [class*='-btn']
{
    width: 260px;

    text-align: center;
}

.payments-total
{
    font-family: 'clarendon';
    font-size: 16px;
    line-height: 22px;

    width: 245px;
    margin-top: 20px;
    padding: 20px 30px;

    text-align: left;

    color: #8cc25c;
    border: 1px solid #8cc25c;
    background-color: #edfddf;
}

.payments-total ._price
{
    font-family: 'clarendon bold';
    font-size: 24px;

    padding: 15px 0;

    color: #8cc25c;
}

.payments-total [class*='-btn']
{
    font-size: 24px;
}

.fieldset-payments
{
    position: absolute;
    top: 50%;
    right: 0;

    margin-top: -25px;
}

.fieldset-payments .payments
{
    position: absolute;
    top: 50%;
    right: 100%;

    margin-top: -16px;
    margin-right: 25px;

    white-space: nowrap;
}

.fieldset-payments .payments a + a
{
    margin-left: 6px;
}

.thank-you
{
    padding-bottom: 50px;

    text-align: center;
}

.thank-you ._title
{
    font-family: 'clarendon bold';
    font-size: 30px;

    display: flex;

    margin-bottom: 20px;

    color: #ed1b2e;

    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.thank-you ._title [class*='-icon']
{
    font-family: 'svg-fonts';
    font-size: 0;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;

    width: auto;
    height: auto;
    margin-right: 15px;

    text-decoration: none;
    text-indent: 0;
    text-transform: none;

    background: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.thank-you ._title [class*='-icon']:before
{
    content: '';
}

.thank-you ._title [class*='-icon']:before
{
    font-size: 24px;

    color: #8cc25c;
}

.thank-you ._subtitle
{
    font-family: 'clarendon';
    font-size: 14px;
    line-height: 18px;

    margin-bottom: 35px;

    color: #8e141f;
}

.thank-you ._order
{
    font-family: 'clarendon bold';
    font-size: 24px;

    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    width: 400px;
    padding: 50px;

    color: #8cc25c;
    border: 1px solid #8cc25c;
    background-color: #edfddf;

    *display: inline;
}

.order-table
{
    font-family: 'clarendon';
    font-size: 14px;
    line-height: 18px;

    width: 100%;

    table-layout: fixed;

    color: #4f2d25;
    border-top: 1px solid #d7d7d7;
}

.order-table ._sb + p
{
    padding-top: 12px;
}

.order-table td
{
    padding: 25px 0 25px 20px;

    vertical-align: middle;

    border-top: 1px solid #d7d7d7;
}

.order-table ._c
{
    text-align: center;
}

.order-table ._r
{
    text-align: right;
}

.order-table ._price
{
    font-size: 16px;
}

.order-table ._edit
{
    padding-top: 4px;
}

.order-table ._edit a
{
    color: #818181;
}

.order-table ._edit a + a
{
    margin-left: 8px;
}

.order-table-title
{
    font-family: 'clarendon bold';
    font-size: 30px;

    padding-bottom: 10px;

    color: #ed1b2e;
}

.order-total
{
    font-family: 'clarendon medium';
    font-size: 30px;

    padding-bottom: 20px;

    color: #4f2d25;
}

.order-notice
{
    position: relative;

    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    *display: inline;
}

.footer-common-indent
{
    height: 108px;
}

.our-wine-card
{
    width: 940px;
    max-width: 100%;
    margin: auto;
}

.our-wine-card .our-wine-item
{
    margin: 30px 0 60px;
    padding: 8px 0;

    border: solid #e61737;
    border-width: 1px 0;
}

.our-wine-card .our-wine-club
{
    float: left;

    margin-right: 45px;
    padding: 0;
}

.our-wine-card .content
{
    font-family: 'clarendon';
    font-size: 16px;
    line-height: 20px;

    padding-right: 10%;

    color: #4f2d25;
}

.our-wine-card .c_title
{
    font-family: 'clarendon bold';
    font-size: 18px;
    line-height: 24px;

    margin: 50px 0 25px;
}

.our-wine-card .absolute-area
{
    position: absolute;
    top: 0;
    left: 100%;

    width: 440px;
    margin-left: 45px;
}

.our-wine-card .absolute-area textarea
{
    height: 168px;
}

.our-wine-card .order-fieldset ._subtitle
{
    font-family: 'clarendon bold';
    font-size: 18px;
}

.our-wine-card fieldset
{
    position: relative;
}

.order-form-responsive,
.order-form-responsive *
{
    box-sizing: border-box;
}

.order-form-responsive .inner
{
    min-width: inherit;
}

.order-form-responsive .text-lead
{
    font-size: 16px;
    line-height: 20px;

    margin: 20px 0 40px;

    text-align: center;
}

.order-form-responsive .order-steps td
{
    white-space: nowrap;
}

.order-form-responsive .order-steps ._n
{
    top: 50%;

    margin-top: -20px;
}

.order-form-responsive .contact-box
{
    display: flex;

    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.order-form-responsive .order-fieldset.dual
{
    display: flex;

    flex-wrap: wrap;
}

.order-form-responsive .order-fieldset.dual ._left,
.order-form-responsive .order-fieldset.dual ._right
{
    float: none;

    width: 50%;
}

.order-form-responsive .order-fieldset.dual ._left fieldset,
.order-form-responsive .order-fieldset.dual ._right fieldset
{
    width: 100%;
    padding: 0;
}

.order-form-responsive .order-fieldset.dual ._left
{
    padding-right: 60px;
}

.order-form-responsive .order-fieldset.dual ._right
{
    padding-left: 60px;
}

.order-form-responsive .order-fieldset.dual .order-fieldset-info
{
    min-width: 100%;
}

.order-form-responsive .order-fieldset ._subtitle
{
    margin: 30px 0;
}

.order-form-responsive .order-fieldset ._subtitle ._subtitle-text
{
    margin-bottom: 15px;
}

.order-form-responsive .order-fieldset ._subtitle .check-the-field
{
    position: relative;

    display: block;
}

.order-form-responsive .order-fieldset fieldset
{
    width: 50%;
    padding-right: 60px;
}

.order-form-responsive .order-fieldset fieldset select
{
    border: 1px solid #bbb;
}

.order-form-responsive .order-fieldset fieldset input:-moz-placeholder
{
    font-family: 'clarendon';

    opacity: 1;
    color: transparent;
}

.order-form-responsive .order-fieldset fieldset input::-moz-placeholder
{
    font-family: 'clarendon';

    opacity: 1;
    color: transparent;
}

.order-form-responsive .order-fieldset fieldset input:-ms-input-placeholder
{
    font-family: 'clarendon';

    color: transparent;
}

.order-form-responsive .order-fieldset fieldset input::-webkit-input-placeholder
{
    font-family: 'clarendon';

    color: transparent;
}

.order-form-responsive .fieldset-payments
{
    display: flex;

    width: 55%;
    margin: 0;
    padding-top: 30px;

    transform: translateY(-50%);

    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.order-form-responsive .fieldset-payments > img
{
    margin: 5px;
}

.order-form-responsive .fieldset-payments .payments
{
    position: static;

    margin: 5px 20px 5px;
}

.order-form-responsive .order-fieldset-info
{
    width: 100%;
    padding-top: 30px;
}

.order-form-responsive .order-fieldset-bottom
{
    padding-top: 40px;
}

.order-form-responsive ._payments-info
{
    padding-top: 30px;
}

.order-form-responsive ._payments-info .payments-total
{
    width: auto;
}

.order-form-responsive ._total .payments-total
{
    width: auto;
}

.order-form-responsive ._total .payments-total .green-btn
{
    display: block;

    width: auto;
}

.order-form-responsive .order-table
{
    border: none;
}

.order-form-responsive .order-table textarea:-moz-placeholder
{
    font-family: 'clarendon';

    opacity: 1;
    color: #4f2d25;
}

.order-form-responsive .order-table textarea::-moz-placeholder
{
    font-family: 'clarendon';

    opacity: 1;
    color: #4f2d25;
}

.order-form-responsive .order-table textarea:-ms-input-placeholder
{
    font-family: 'clarendon';

    color: #4f2d25;
}

.order-form-responsive .order-table textarea::-webkit-input-placeholder
{
    font-family: 'clarendon';

    color: #4f2d25;
}

.order-form-responsive .thank-you ._order
{
    width: 570px;
}

.ui-black section
{
    background-color: transparent;
}

.ui-black #wrapper
{
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: cover;
}

.ui-black.bg1 #wrapper
{
    background-image: url(../img//ui-black/screen_01.jpg);
}

.ui-black.bg2 #wrapper
{
    background-image: url(../img//ui-black/screen_02.jpg);
}

.ui-black.bg3 #wrapper
{
    background-image: url(../img//ui-black/screen_03.jpg);
}

.ui-black.bg4 #wrapper
{
    background-image: url(../img//ui-black/screen_04.jpg);
}

.club-card-item__caption
{
    font-size: 20px;

    padding: 14px 16px;

    text-transform: uppercase;

    color: #fff;
    border: 1px solid #fff;
    background-color: #000;
}

.special-club-article
{
    padding-bottom: 70px;

    color: #fff;
}

.special-club-article > .inner
{
    max-width: 880px;
    margin: auto;
}

.special-club-article__top
{
    padding-top: 65px;

    text-align: center;
}

.special-club-article__top ._title
{
    font-size: 80px;

    padding-top: 30px;
}

.special-club-article__top ._subtitle
{
    font-size: 16px;
    line-height: 22px;

    max-width: 820px;
    margin: auto;
    padding-top: 30px;

    text-transform: uppercase;
}

.special-club-article__top ._subtitle-info
{
    font-size: 24px;

    padding-top: 30px;

    text-transform: uppercase;
}

.special-club-article__top img
{
    max-width: 100%;
}

.club-card-list
{
    margin-top: 40px;
}

.club-card-item
{
    padding: 30px;

    border-top: 1px solid #707070;
}

.clc-form__item label
{
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    margin-right: 20px;
    padding-top: 8px;

    *display: inline;
}

.clc-form__item label input
{
    margin: 0 8px 0 0;
    padding: 0;

    background-color: transparent;
}

.clc-form__item + .clc-form__item
{
    margin-top: 8px;
}

.club-card-title
{
    font-size: 24px;
}

.club-card-description
{
    font-size: 19px;
    line-height: 20px;

    padding-top: 15px;
}

.club-card-slide
{
    float: left;

    box-sizing: border-box;
    width: 100%;
    max-width: 300px;
    min-height: 176px;
    margin-right: 42px;
    padding: 18px;

    text-align: center;
    text-transform: uppercase;

    border: 1px solid #fff;
    background-color: #000;
}

.club-card-slide ._dbl_line
{
    position: relative;

    width: 150px;
    margin: 12px auto;

    border-bottom: 1px solid #434343;
}

.club-card-slide ._dbl_line:after
{
    position: absolute;
    top: 3px;
    right: -14px;
    left: -14px;

    display: block;

    content: '';

    border-bottom: 1px solid #434343;
}

.club-card-slide ._the
{
    font-size: 30px;
}

.club-card-slide ._the_title
{
    font-size: 14px;
}

.club-card-slide ._price
{
    font-size: 36px;

    margin-bottom: 10px;
}

.club-card-slide ._cond
{
    font-size: 12px;
}

.club-card-description__bottom
{
    position: relative;

    margin-top: 20px;

    text-align: right;
}

.club-card-description__bottom > ._btn
{
    font-family: 'SansSerifDemibold', Arial, sans-serif;
    font-size: 16px;

    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    padding: 10px 20px;

    cursor: pointer;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #fff;
    border: 1px solid #b7b7b7;
    background-color: #707070;

    *display: inline;
}

.club-card-description__bottom > ._btn:hover
{
    background-color: #636363;
}

.club-card-description__bottom > ._info
{
    position: absolute;
    top: 14px;
    left: 0;

    text-transform: uppercase;
}

.club-card-item__form
{
    padding: 25px 0;

    border-bottom: 1px solid #707070;
}

.club-card-item__form > ._desc
{
    font-size: 18px;

    margin-bottom: 20px;

    text-align: center;
}

.club-card-item__form ._f-item
{
    font-size: 14px;

    position: absolute;
    top: 0;
    right: 100%;

    box-sizing: border-box;
    padding-top: 8px;
    padding-right: 24px;

    text-align: right;
    white-space: nowrap;
}

.club-card-item__form ._f-field
{
    position: relative;

    margin-left: 148px;
}

.club-card-item__form + .club-card-description__bottom
{
    margin: 30px 90px;
}

.clc-form__input
{
    background-color: rgba(102, 102, 102, .9);
}

.clc-form__input input,
.clc-form__input textarea
{
    font-family: 'SansSerif', Arial, sans-serif;
    font-size: 14px;

    padding: 5px 7px;

    color: #fff;
    border: 1px solid #fff;
    box-shadow: 0 0 0 0 transparent;
}

.clc-form__input input:focus,
.clc-form__input textarea:focus
{
    color: #fff;
}

.clc-form__input textarea
{
    min-height: 176px;
}

.clc-important
{
    font-size: 15px;

    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;

    color: #ed1b2e;

    *display: inline;
}

.clc-form__item .clc-important
{
    position: absolute;
    top: 0;
    left: 100%;

    padding: 6px 0 0 14px;
}

.club-card-description__bottom .clc-important
{
    margin-right: 10px;
}

.clc-form-txt
{
    max-width: 380px;
}

.clc-left
{
    padding-right: 30px;
}

.clc-right
{
    padding-left: 30px;
}

.clc-left,
.clc-right
{
    float: left;

    box-sizing: border-box;
    width: 50%;
}

.special-club-success
{
    font-size: 18px;
    line-height: 24px;

    margin-top: 40px;
    padding: 70px 0;

    text-align: center;

    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
}

.special-club-success ._title
{
    font-size: 30px;

    margin-bottom: 30px;
}

.special-club-success ._title .fa
{
    color: #8dc258;
}

.section-merchants
{
    position: relative;

    text-align: center;
}

.section-merchants .app-section-content
{
    position: relative;
    z-index: 1;

    max-width: 680px;
    margin-right: auto;
    margin-left: auto;
}

.section-merchants__back
{
    position: absolute;
    top: 50%;
    left: 0;

    width: 200px;
    max-width: 15%;

    transform: translateY(-50%);
}

.app-section-subtitle
{
    font-size: 24px;
    font-weight: bold;

    margin-bottom: 30px;
}

.app-section-title
{
    font-family: 'script';
    font-size: 30px;

    margin-bottom: 20px;

    text-align: center;

    color: #ed1b2e;
}

.app-section__inner
{
    margin-right: auto;
    margin-left: auto;
    padding: 0 20px;
}

.app-section-content
{
    font-size: 16px;
    line-height: 22px;
}

.app-section
{
    position: relative;

    padding: 28px 0;
}

.section-event
{
    text-align: center;

    background-color: #faf5e2;
}

.section-event .app-section__inner
{
    max-width: 1100px;
}

.section-event.app-section
{
    padding-top: 60px;
    padding-bottom: 60px;
}

.section-event .app-btn
{
    margin-top: 30px;
}

.section-event .section-event__calendar
{
    margin-bottom: 30px;
}

.section-event__grid
{
    display: flex;
    flex-direction: column;

    align-items: center;
}

.app-btn
{
    font-family: 'clarendon medium';
    font-size: 16px;

    display: inline-block;

    box-sizing: border-box;
    min-width: 40px;
    padding: 8px 22px 8px;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;

    border: 1px solid transparent;
    box-shadow: inset 1px 1px 1px rgba(255, 255, 255, .4), inset -1px -1px 1px rgba(0, 0, 0, .15), 2px 2px 1px rgba(0, 0, 0, .2);
}

.app-btn:hover
{
    transition-timing-function: ease-out;
    transition-duration: .2s;
    transition-property: all;
}

.app-primary-btn
{
    color: white;
    background-color: #ab001a;
}

.app-primary-btn:hover
{
    background-color: #c5001e;
}

.section-satisfaction
{
    position: relative;

    max-width: 1400px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 !important;

    box-shadow: 0 0 0 7px white, 0 0 0 14px #ed1b2e;
}

.section-satisfaction .section-satisfaction__label
{
    position: absolute;
    bottom: 0;
    left: 50%;

    height: 88px;

    transform: translate(-50%, 50%);
}

.section-satisfaction .section-satisfaction__label img
{
    height: 100%;
}

.section-satisfaction > img
{
    width: 100%;
}

.section-drinks
{
    padding-top: 60px;

    text-align: center;
}

.section-drinks .app-section__inner
{
    position: relative;
    z-index: 1;

    max-width: 620px;
}

.section-cap-title
{
    font-family: 'script';
    font-size: 46px;

    padding: 28px 0;

    text-align: center;

    color: #fff;
    background: url(../img/section-cap-bg.jpg) 50% 50% no-repeat;
    background-size: cover;
}

.section-event__time
{
    font-family: 'script light';

    white-space: nowrap;
}

.section-event__date
{
    font-family: 'script';

    white-space: nowrap;
}

.section-event__calendar-inner
{
    margin-top: 28px;
    padding: 16px 0;

    border-width: 1px 0;
    border-style: solid;
    border-color: #ed1b2e;
}

.section-event__calendar
{
    font-size: 28px;
    line-height: 36px;

    display: inline-block;

    text-align: center;

    color: #ed1b2e;
}

.section-event__calendar > img
{
    width: 58px;
}

.app-toggler-btn
{
    position: absolute;
    top: 0;
    right: 0;

    display: inline-flex;

    box-sizing: border-box;
    width: 82px;
    height: 60px;
    padding-bottom: 7px;

    cursor: pointer;

    background-color: #ab001a;

    justify-content: center;
    align-items: center;
}

.app-toggler-btn__ico
{
    position: relative;
    top: 0;
    right: 0;

    width: 36px;
    height: 25px;

    vertical-align: top;
}

.app-toggler-btn__ico span
{
    position: absolute;

    width: 100%;
    height: 4px;

    transition: .25s ease-out;

    border-radius: 2px;
    background-color: #fff;
}

.app-toggler-btn__ico span:nth-child(1)
{
    top: 0;
    left: 0;
}

.app-drawer-opened .app-toggler-btn__ico span:nth-child(1)
{
    top: 50%;

    margin-top: -2px;

    transform: rotate(45deg);
}

.app-toggler-btn__ico span:nth-child(2)
{
    top: 50%;
    left: 0;

    margin-top: -2px;

    opacity: 1;
}

.app-drawer-opened .app-toggler-btn__ico span:nth-child(2)
{
    opacity: 0;
}

.app-toggler-btn__ico span:nth-child(3)
{
    bottom: 0;
    left: 0;
}

.app-drawer-opened .app-toggler-btn__ico span:nth-child(3)
{
    top: 50%;

    margin-top: -2px;

    transform: rotate(-45deg);
}

.app-hero > img
{
    width: 100%;
    max-height: 100%;

    -o-object-fit: cover;
    object-fit: cover;
}

.section-hero-sm
{
    height: 100px;
}

.app-drawer
{
    position: fixed;
    z-index: 9;
    top: 60px;
    right: 0;

    width: 500px;
    max-width: 80%;
    height: calc(100% - 60px);
    height: calc(100vh - 60px);

    transition: transform .25s ease-in-out;
    transform: translateX(100%);

    color: #fff;
    background-color: #e61737;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, .25);
}

.app-drawer-opened .app-drawer
{
    transform: translateX(0);
}

.app-drawer__scroll
{
    overflow-x: hidden;
    overflow-y: auto;

    width: 100%;
    height: 100%;
}

.app-drawer__inner
{
    padding: 40px;

    text-align: center;
}

.app-drawer__nav
{
    font-size: 20px;
    font-weight: bold;

    padding-bottom: 20px;
}

.app-drawer__nav > a
{
    line-height: 1.25;

    display: block;

    padding: 10px 0;

    color: inherit;
}

.app-drawer__social
{
    display: flex;

    justify-content: center;
    align-items: center;
}

.app-drawer__social > a
{
    margin: 0 10px;
}

.app-footer-mobile
{
    font-size: 14px;
    font-weight: normal;

    margin-top: 6px;
    padding: 20px;

    color: rgba(255, 255, 255, .5);
}

.app-footer-mobile__inner
{
    max-width: 280px;
    margin-right: auto;
    margin-left: auto;

    text-align: center;
}

.section-satisfaction__img
{
    position: relative;
}

.section-satisfaction__img > img
{
    position: absolute;

    display: block;

    width: 100%;
    height: 100%;

    -o-object-fit: cover;
    object-fit: cover;
}

.section-satisfaction__images
{
    position: relative;

    overflow: hidden;
}

.section-satisfaction__images:before
{
    display: block;

    width: 100%;
    padding-top: 63.11111%;

    content: '';
}

.section-satisfaction__images .section-satisfaction__img
{
    position: absolute;

    display: none;

    width: 100%;
    height: 100%;

    -o-object-fit: cover;
    object-fit: cover;
}

.section-satisfaction__images .section-satisfaction__img:nth-child(1)
{
    top: 0;
    left: 0;

    display: block;

    width: 58%;
    height: 56%;
}

.section-satisfaction__images .section-satisfaction__img:nth-child(2)
{
    bottom: 0;
    left: 0;

    display: block;

    width: 24%;
    height: calc(44% - 8px);
}

.section-satisfaction__images .section-satisfaction__img:nth-child(3)
{
    bottom: 0;
    left: calc(24% + 8px);

    display: block;

    width: calc(34% - 8px);
    height: calc(44% - 8px);
}

.section-satisfaction__images .section-satisfaction__img:nth-child(4)
{
    top: 0;
    right: 0;

    display: block;

    width: calc(42% - 8px);
    height: 100%;
}

.satisfaction-modal__inner
{
    position: relative;

    box-sizing: border-box;
    max-width: 100%;
    max-height: 60vh;
    padding: 40px 0 0;

    background-color: #fff;
}

.satisfaction-modal__inner .swiper-container-horizontal > .swiper-pagination-bullets,
.satisfaction-modal__inner .swiper-pagination-custom,
.satisfaction-modal__inner .swiper-pagination-fraction
{
    bottom: 24px;
}

.satisfaction-modal__inner .swiper-container
{
    box-sizing: border-box;
    height: 100%;
    padding-bottom: 60px;

    background-color: #fff;

    --swiper-pagination-color: #ed1b2e;
    --swiper-navigation-color: #bd2d28;
}

.satisfaction-modal__inner .swiper-container:not(:hover) [class*='swiper-button-']
{
    visibility: hidden;
}

.satisfaction-modal__inner .swiper-button-prev
{
    left: 0;
}

.satisfaction-modal__inner .swiper-button-next
{
    right: 0;
}

.satisfaction-modal__inner [class*='swiper-button-']
{
    top: 0;

    display: flex;

    width: 80px;
    height: 100%;
    margin: 0;

    justify-content: center;
    align-items: center;
}

.satisfaction-modal__inner [class*='swiper-button-']:hover
{
    transition: background-color .25s ease-out;

    background-color: rgba(255, 255, 255, .8);
}

.satisfaction-modal__close
{
    font-size: 12px;
    line-height: 1;

    position: absolute;
    top: 4px;
    right: 14px;

    padding: 10px;

    cursor: pointer;
    text-transform: lowercase;
}

.satisfaction-modal
{
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;

    display: flex;

    width: 100%;
    height: 100%;

    background-color: rgba(189, 45, 40, .8);

    justify-content: center;
    align-items: center;
}

.satisfaction-modal .swiper-slide
{
    width: auto;
}

.satisfaction-modal .swiper-slide img
{
    height: 500px;
    height: 50vh;
    max-height: 580px;
}

.clubs-responsive
{
    padding-bottom: 100px;
}

.clubs-responsive,
.clubs-responsive *
{
    box-sizing: border-box;
}

.clubs-responsive #main-slider .inner
{
    min-width: initial;
}

.clubs-responsive #main-slider .title
{
    padding: 0;
}

.clubs-responsive .our-wine-club .title
{
    background-size: 100%;
}

.clubs-responsive .our-wine-club .title span
{
    display: inline-block;
}

.clubs-responsive .club-list
{
    font-size: 18px;
    line-height: 22px;

    margin: 30px 0;
}

.clubs-responsive .club-list .club-list-title
{
    font-family: 'script';
    font-size: 30px;
    line-height: 40px;

    color: #ed1b2e;
}

.clubs-responsive .club-list ul
{
    padding: 0;

    list-style: none;
}

.clubs-responsive .club-list ul li
{
    position: relative;

    padding-left: 20px;
}

.clubs-responsive .club-list ul li + li
{
    margin-top: 8px;
}

.clubs-responsive .club-list ul li span
{
    position: absolute;
    top: 0;
    left: 0;

    color: #ed1b2e;
}

.clubs-responsive .clubs-list
{
    padding: 50px;

    border: 8px solid #faf5e2;
    background: #fffcf0;
}

.clubs-responsive .clubs-list-item + .clubs-list-item
{
    margin-top: 40px;
    padding-top: 40px;

    border-top: 1px solid #e2d5a2;
}

.clubs-responsive .clubs-list-item .item-title
{
    font-family: 'clarendon bold';
    font-size: 24px;
    line-height: 24px;

    margin-bottom: 20px;
}

.clubs-responsive .clubs-list-item .item-title span
{
    text-transform: uppercase;

    color: #ed1b2e;
}

.clubs-responsive .clubs-list-item .item-title small
{
    font-size: 16px;
}

.clubs-responsive .clubs-list-item .item-text
{
    font-size: 18px;
    line-height: 20px;
}

.clubs-responsive .clubs-list-item .item-button
{
    margin-top: 30px;
}

.events-responsive,
.events-responsive *
{
    box-sizing: border-box;
}

.events-responsive #main-slider .inner
{
    min-width: initial;
}

.events-responsive #main-slider .title
{
    padding-top: 0;
}

.events-responsive .private-events
{
    position: relative;
}

.events-responsive .private-events .bouble-header
{
    background: none;
}

.events-responsive .private-events .bouble-header img
{
    display: block;

    max-width: 100%;
    margin-left: 0;

    transform: translate(-50%, 34%);
}

.events-responsive .private-events .inner
{
    min-width: initial;
    padding-right: 20px;
    padding-left: 20px;
}

.events-responsive .section-satisfaction
{
    margin-top: 20px;
    margin-bottom: 20px;
}

.events-responsive .events-list-title
{
    padding-top: 60px;
    padding-bottom: 60px;
}

.events-responsive .events-list-title .title
{
    font-family: 'script light';
    font-size: 80px;
    line-height: 80px;

    text-align: center;

    color: #ed1b2e;
    background: url(../img/wine-title-bg.jpg) 50% 50% no-repeat;
    background-size: 100%;
}

.events-responsive .events-list-title .title span
{
    display: inline-block;

    padding: 0 20px;

    background-color: #fff;
}

.events-responsive .events-list-wrap .inner
{
    min-width: initial;
}

.events-responsive .events-list-wrap .list-item-head
{
    font-size: 24px;
    line-height: 30px;

    padding: 20px 0;

    cursor: pointer;

    color: #fff;
    background: #cb2232;
}

.events-responsive .events-list-wrap .list-item-head.opened .ico
{
    transform: rotate(180deg);
}

.events-responsive .events-list-wrap .list-item-head .g-container
{
    display: flex;

    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.events-responsive .events-list-wrap .list-item-head .ico
{
    position: relative;

    display: block;

    width: 30px;
    min-width: 30px;
    height: 30px;
    margin-left: 20px;

    transition: transform .4s ease;

    border-radius: 50%;
    background: rgba(255, 255, 255, .5);
}

.events-responsive .events-list-wrap .list-item-head .ico:before
{
    position: absolute;
    top: 50%;
    left: 50%;

    display: block;

    margin-top: 1px;

    content: '';
    transform: translate(-50%, -50%);

    border: 6px solid transparent;
    border-top: 10px solid #fff;
    border-bottom: none;
}

.events-responsive .events-list-wrap .list-item-body
{
    padding-top: 20px;
    padding-bottom: 20px;
}

.events-responsive .events-list-wrap .item-image
{
    width: 300px;
    min-width: 300px;
    height: 300px;
    margin-right: 30px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.events-responsive .events-list-wrap .item-title
{
    font-family: 'script';
    font-size: 36px;
    line-height: 40px;

    margin-bottom: 10px;

    color: #e61737;
}

.events-responsive .events-list-wrap .item-text
{
    font-size: 16px;
    line-height: 20px;
}

.events-responsive .events-list-wrap .item-text p
{
    margin: 0;
}

.events-responsive .events-list-wrap .item-text p + p
{
    margin-top: 20px;
}

.events-responsive .events-list-wrap .item-features
{
    font-size: 16px;
    line-height: 20px;
}

.events-responsive .events-list-wrap .item-features ul
{
    margin-top: 0;
    padding-left: 20px;
}

.events-responsive .events-list-wrap .item-features-title
{
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;

    margin: 30px 0 20px;
    padding: 5px 10px;

    text-transform: uppercase;

    color: #e61737;
    background: #faf5e2;
}

.events-responsive .events-list-wrap .item-features-row
{
    margin-right: -10px;
    margin-left: -10px;
}

.events-responsive .events-list-wrap .item-features-col
{
    padding: 0 10px;
}

.events-responsive .events-list-wrap .item-button
{
    margin-top: 20px;
}

.events-responsive .events-list-wrap .item-button .red-btn
{
    min-width: 300px;
}

.events-responsive .events-contacts
{
    padding-top: 60px;
    padding-bottom: 100px;
}

.events-responsive .events-contacts .inner
{
    min-width: initial;
}

.events-responsive .events-contacts .title
{
    font-family: 'script';
    font-size: 36px;
    line-height: 40px;

    margin-bottom: 10px;

    color: #e61737;
}

.events-responsive .events-contacts .text
{
    font-size: 16px;
    line-height: 20px;
}

.events-responsive .events-contacts .phone,
.events-responsive .events-contacts .mail
{
    display: flex;

    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
}

.r_container
{
    box-sizing: border-box;
    padding-right: 14px;
    padding-left: 14px;
}

.r_contact-information
{
    padding: 70px 0 100px;
}

.r_contact-information .r_inner
{
    font-size: 18px;
    line-height: 1.45;

    padding-bottom: 40px;

    text-align: center;
}

.r_contact-information .r_content
{
    max-width: 480px;
    margin-right: auto;
    margin-left: auto;
}

.r_contact-information-title
{
    font-family: 'blair';
    font-size: 32px;

    margin-bottom: 30px;

    color: #ed1b2e;
}

.r_contact-hours
{
    display: flex;
    flex-direction: column;

    max-width: 90%;
    margin-right: auto;
    margin-left: auto;

    justify-content: center;
    align-items: center;
}

.r_contact-hours .r_contact-hours-box
{
    display: flex;

    width: 380px;
    max-width: 100%;

    text-align: left;
}

.r_contact-hours .r_contact-hours-box .r_title
{
    flex: 1;
}

.r_contact-hours .r_contact-hours-box:not(:first-child)
{
    margin: 6px 0 0;
}

.r_contact-hours-box
{
    min-width: 0;

    text-align: center;

    flex: 1;
}

.r_contact-title
{
    font-family: 'script light';
    font-size: 40px;

    margin-bottom: 40px;

    color: #e61737;
}

.r_inner
{
    position: relative;

    box-sizing: border-box;
    width: 1280px;
    max-width: 100%;
    margin: 0 auto;
}

.r_contact-box-title
{
    font-family: 'script light';
    font-size: 40px;

    margin-bottom: 50px;
}

.r_contact-separate-box
{
    display: flex;
    flex-direction: column-reverse;

    border: solid #fff;
    border-width: 5px 0;
    background-color: #faf5e2;
    box-shadow: 0 0 0 1px #af9581;
}

.r_contact-separate-box #map
{
    height: 100%;
    min-height: 360px;

    border-right: 5px solid #fff;
}

.r_contact-box
{
    font-family: 'blair';
    font-size: 18px;

    position: relative;

    max-width: 450px;
    margin: auto;
    padding: 40px 30px;

    color: #e61737;
}

.r_contact-box [href]
{
    color: inherit;
}

.r_contact-box p
{
    line-height: 32px;

    position: relative;

    padding-left: 40px;
}

.r_contact-box p i
{
    position: absolute;
    top: 3px;
    left: 0;
}

.r_contact-box p + p
{
    margin-top: 18px;
}

.table-modal__title
{
    font-family: 'clarendon bold';
    font-size: 36px;
    line-height: 1.4;

    color: #b2323d;
    border-bottom: 1px solid #aaa;
}

.table-modal__h-text
{
    font-family: 'clarendon bold';
    font-size: 16px;
    line-height: 1.45;

    color: #4f2d25;
}

.table-modal__subtitle
{
    font-family: 'clarendon bold';
    font-size: 16px;
    line-height: 1.45;

    padding-top: 30px;

    color: #ed1b2e;
}

@media screen and (min-width: 450px)
{
    .section-event
    {
        background-image: url(../img/white-glass.png);
        background-repeat: no-repeat;
        background-position: 95% 100%;
    }
}

@media (min-width: 460px)
{
    .hidden-xs-min
    {
        display: none !important;
    }

    .g-container
    {
        max-width: 460px;
    }
}

@media (min-width: 768px)
{
    .hidden-sm-min
    {
        display: none !important;
    }

    .g-container
    {
        max-width: 760px;
    }

    .events-responsive .events-list-wrap .item-features-row
    {
        display: flex;

        flex-wrap: nowrap;
    }

    .events-responsive .events-list-wrap .item-features-col
    {
        max-width: 50%;

        flex: 0 0 50%;
    }
}

@media (min-width: 998px)
{
    .hidden-md-min
    {
        display: none !important;
    }

    .g-container
    {
        max-width: 980px;
    }

    .shopping-page-responsive .contact-box.contact-box--top
    {
        display: none;
    }

    .shopping-page-responsive .contact-box.contact-box--bottom
    {
        border: none;
    }

    .shopping-page-responsive .cart-gift-card .gift-card-item .content.content--top
    {
        display: none;
    }

    .order-form-responsive .order-fieldset ._subtitle
    {
        padding-left: 32px;
    }

    .clubs-responsive .clubs-list-inner
    {
        max-width: none;
        padding: 0;
    }

    .events-responsive .events-list-wrap .list-item + .list-item
    {
        margin-top: 60px;
    }

    .events-responsive .events-list-wrap .list-item-head
    {
        display: none;
    }

    .events-responsive .events-list-wrap .list-item-body
    {
        display: flex !important;

        max-width: none;
        padding: 0;

        flex-wrap: nowrap;
    }

    .events-responsive .events-list-wrap .item-wrap
    {
        max-width: none;
        padding: 0;
    }

    .events-responsive .events-contacts .inner
    {
        padding-left: 350px;
    }
}

@media screen and (min-width: 998px)
{
    .app-mobile-visible
    {
        display: none;
    }

    header .logotype
    {
        left: 0;

        width: 324px;
    }

    #main-slider .title
    {
        font-size: 76px;
        line-height: 76px;
    }

    .quote-box
    {
        padding: 100px 0;
    }

    .quote
    {
        font-size: 24px;
        line-height: 45px;

        padding-left: 80px;
    }

    footer
    {
        height: 53px;
        padding-top: 39px;

        background-color: transparent;
        background-image: url(../img/footer-bg.png), url(../img/footer-repeat.png);
        background-repeat: no-repeat, repeat-x;
        background-position: 50% 100%, 0 0;
        background-size: 170px, auto;
    }

    .shopping-page .title
    {
        font-size: 100px;
    }

    .shopping-page .subtitle
    {
        max-width: 1060px;
    }

    .shopping-page .contact-box
    {
        flex-direction: row;
    }

    .shopping-page .contact-box .label
    {
        margin: 0 15px 0 0;
    }

    .order-fieldset ._fielditem.important:after
    {
        font-family: 'clarendon medium';
        font-size: 30px;

        position: absolute;
        top: 6px;
        right: 5px;

        content: '*';

        color: #ed1b2e;
    }

    .app-section-title
    {
        font-size: 48px;

        margin-bottom: 34px;
    }

    .app-section
    {
        padding: 42px 0;
    }

    .section-event
    {
        text-align: left;
    }

    .section-event .section-event__calendar
    {
        margin: 0 10% 0 20px;

        transform: translateY(-10%);
    }

    .section-event__grid
    {
        flex-direction: row;
    }

    .section-event__grid .section-event__calendar
    {
        align-self: center;
    }

    .section-satisfaction .section-satisfaction__label
    {
        height: 134px;
    }

    .section-drinks
    {
        padding-top: 80px;
    }

    .section-cap-title
    {
        font-size: 80px;

        padding: 36px 0;
    }

    .section-event__calendar
    {
        font-size: 34px;
        line-height: 40px;
    }

    .section-satisfaction__images
    {
        position: relative;
    }

    .section-satisfaction__images:before
    {
        display: block;

        width: 100%;
        padding-top: 40.68136%;

        content: '';
    }

    .section-satisfaction__images .section-satisfaction__img:nth-child(1)
    {
        top: 0;
        left: 0;

        display: block;

        width: 35%;
        height: 56%;
    }

    .section-satisfaction__images .section-satisfaction__img:nth-child(2)
    {
        bottom: 0;
        left: 0;

        display: block;

        width: 13%;
        height: calc(44% - 8px);
    }

    .section-satisfaction__images .section-satisfaction__img:nth-child(3)
    {
        bottom: 0;
        left: calc(13% + 8px);

        display: block;

        width: calc(22% - 8px);
        height: calc(44% - 8px);
    }

    .section-satisfaction__images .section-satisfaction__img:nth-child(4)
    {
        top: 0;
        right: 0;
        left: 0;

        display: block;

        width: calc(30% - 16px);
        height: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    .section-satisfaction__images .section-satisfaction__img:nth-child(5)
    {
        top: 0;
        right: 0;

        display: block;

        width: 35%;
        height: 56%;
    }

    .section-satisfaction__images .section-satisfaction__img:nth-child(6)
    {
        bottom: 0;
        left: 65%;

        display: block;

        width: 10%;
        height: calc(44% - 8px);
    }

    .section-satisfaction__images .section-satisfaction__img:nth-child(7)
    {
        right: 0;
        bottom: 0;

        display: block;

        width: calc(25% - 8px);
        height: calc(44% - 8px);
    }

    .satisfaction-modal__inner
    {
        max-width: 80vw;
        max-height: 80vh;
        padding: 40px 40px 0;
    }

    .satisfaction-modal .swiper-slide
    {
        width: auto;
    }

    .satisfaction-modal .swiper-slide img
    {
        height: 500px;
        height: 50vh;
        max-height: 580px;
    }

    .r_container
    {
        padding-right: 24px;
        padding-left: 24px;
    }

    .r_contact-information .r_content
    {
        max-width: 1280px;
    }

    .r_contact-hours
    {
        flex-direction: row;
    }

    .r_contact-hours .r_contact-hours-box
    {
        flex-direction: column;

        text-align: center;
    }

    .r_contact-hours .r_contact-hours-box:not(:first-child)
    {
        margin: 0 0 0 20px;
    }

    .r_contact-separate-box
    {
        flex-direction: row;
    }

    .r_contact-separate-box .r_left-panel,
    .r_contact-separate-box .r_right-panel
    {
        min-width: 0;

        flex: 1;
    }

    .r_contact-separate-box .r_contact-box
    {
        font-size: 24px;

        padding-top: 50px;
        padding-bottom: 50px;
    }

    .with-parallax
    {
        position: relative;

        overflow: hidden;

        height: 100vh;
    }

    .with-parallax .r_contact-information
    {
        padding-bottom: 60px;
    }

    .with-parallax .footer
    {
        position: relative;
    }

    .with-parallax #wrapper
    {
        overflow-x: hidden;
        overflow-y: auto;

        height: 100%;
        height: 100vh;

        perspective: 300px;
    }

    .with-parallax #main-slider
    {
        position: relative;

        overflow: hidden;

        height: 360px;
        height: 40vh;
        max-height: 360px;

        transform-style: preserve-3d;
    }

    .with-parallax #main-slider .image-box
    {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        transform: translateZ(-40px) scale(1.5);
    }
}

@media screen and (min-width: 1260px)
{
    #main-slider .title
    {
        font-size: 100px;
        line-height: 100px;
    }

    .private-articles .article-images
    {
        left: 40px;
    }
}

@media (min-width: 1280px)
{
    .hidden-lg-min
    {
        display: none !important;
    }

    .g-container
    {
        max-width: 1060px;
    }

    .calendar-responsive .social-order table.social ._cell
    {
        font-size: 16px;
        line-height: 20px;

        height: 200px;
    }

    .order-form-responsive .fieldset-payments > img
    {
        order: 2;
    }

    .order-form-responsive .fieldset-payments .payments
    {
        order: 1;
    }
}

@media screen and (max-width: 1300px)
{
    header a
    {
        font-size: 14px;
    }
}

@media (max-width: 1279px)
{
    .hidden-md-max
    {
        display: none !important;
    }

    .calendar-responsive .social-order .month-title
    {
        font-size: 46px;

        margin-bottom: -55px;
    }

    .calendar-responsive .social-order .table-informer .left,
    .calendar-responsive .social-order .table-informer .right
    {
        width: 150px;
    }

    .order-form-responsive .order-steps ._step
    {
        font-size: 26px;
    }

    .order-form-responsive .order-fieldset.dual ._left
    {
        padding-right: 30px;
    }

    .order-form-responsive .order-fieldset.dual ._right
    {
        padding-left: 30px;
    }

    .order-form-responsive .order-fieldset fieldset
    {
        padding-right: 30px;
    }

    .order-form-responsive .fieldset-payments
    {
        width: 50%;
    }
}

@media screen and (max-width: 1260px)
{
    #main-slider .subtitle
    {
        font-size: 24px;
        line-height: 36px;

        padding-top: 30px;
    }

    .drinks-box .sort a
    {
        width: 150px;
    }

    .drinks-box .sort a .spr-view
    {
        line-height: 150px;
    }

    .special-events .title
    {
        font-size: 76px;
    }

    .events-content .main-description .title .date
    {
        line-height: 40px;

        display: block;

        padding: 10px 0 0;
    }

    .post .post-item p
    {
        font-size: 16px;
        line-height: 35px;
    }

    .post .post-item img
    {
        max-width: 400px;
    }

    .banner.bottle-offering
    {
        font-size: 24px;
        line-height: 48px;
    }

    .special-about-post img
    {
        width: 400px;
    }

    .not-forget-self .title
    {
        font-size: 76px;
        line-height: 76px;
    }

    .not-forget-self .subtitle
    {
        font-size: 24px;
        line-height: 36px;
    }

    .sip-info .title
    {
        font-size: 24px;
        line-height: 36px;
    }

    .our-wine-club .bg
    {
        padding-top: 20px;
    }

    .our-wine-club .indent
    {
        margin: 0 2px;
    }

    .table-informer .templates span
    {
        font-size: 18px;
    }

    table.social ._cell
    {
        font-size: 14px;
        line-height: 18px;

        height: 120px;
    }

    table.social ._cell ._closed
    {
        padding-top: 10px;
    }
}

@media screen and (max-width: 1110px)
{
    header a
    {
        font-size: 11px;
    }
}

@media (max-width: 997px)
{
    .hidden-sm-max
    {
        display: none !important;
    }

    .calendar-responsive #main-slider .title
    {
        font-size: 70px;
    }

    .calendar-responsive .social-order
    {
        padding-top: 40px;
        padding-right: 0;
        padding-left: 0;
    }

    .calendar-responsive .social-order .month-title
    {
        font-size: 40px;

        margin-bottom: 40px;
    }

    .calendar-responsive .social-order .month-title > span
    {
        display: flex;

        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }

    .calendar-responsive .social-order .month-title .slide-left
    {
        margin-right: 0;
    }

    .calendar-responsive .social-order .month-title .slide-right
    {
        margin-left: 0;
    }

    .calendar-responsive .social-order .table-informer
    {
        display: none;
    }

    .calendar-responsive .social-order .social-container
    {
        padding: 0;

        border-right: none;
        border-left: none;
    }

    .calendar-responsive .social-order table.social ._cell
    {
        font-size: 16px;
        line-height: 20px;
    }

    .calendar-responsive .social-order table.social
    {
        border-collapse: collapse;

        border: none;
    }

    .calendar-responsive .social-order table.social thead
    {
        display: none;
    }

    .calendar-responsive .social-order table.social tbody
    {
        display: block;
    }

    .calendar-responsive .social-order table.social tr
    {
        display: block;
    }

    .calendar-responsive .social-order table.social td
    {
        display: block;

        padding: 0;

        border: none;
        border-top: 1px solid #e4dbb4;
    }

    .calendar-responsive .social-order table.social td.empty
    {
        display: none;
    }

    .calendar-responsive .social-order table.social ._cell
    {
        display: flex;

        height: auto;
        min-height: 120px;
        padding: 5px 0;

        flex-wrap: nowrap;
    }

    .calendar-responsive .social-order table.social ._cell:hover
    {
        background-color: #faf5e2;
    }

    .calendar-responsive .social-order table.social ._cell ._d
    {
        font-size: 30px;

        display: flex;
        flex-direction: column;

        width: 100px;
        min-width: 100px;
        margin-right: 20px;
        padding: 0;

        text-align: center;

        background: #e4dbb4;

        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
    }

    .calendar-responsive .social-order table.social ._cell ._d span
    {
        font-size: 16px;

        display: block;

        margin-bottom: 5px;

        text-transform: uppercase;
    }

    .calendar-responsive .social-order table.social ._cell ._wrap
    {
        padding: 5px 10px 5px 0;

        text-align: left;

        flex: 1;
        align-self: center;
    }

    .calendar-responsive .social-order table.social ._cell ._closed
    {
        padding: 0;

        text-align: left;
    }

    .shopping-page-responsive
    {
        padding-top: 60px;
    }

    .shopping-page-responsive .title
    {
        font-size: 70px;
    }

    .shopping-page-responsive .subtitle
    {
        display: none;
    }

    .shopping-page-responsive .contact-box .label
    {
        font-size: 40px;
    }

    .shopping-page-responsive .contact-box.contact-box--bottom
    {
        margin-bottom: 20px;
        padding-top: 40px;
        padding-bottom: 0;

        border-bottom: none;
    }

    .shopping-page-responsive .contact-box.contact-box--bottom .label,
    .shopping-page-responsive .contact-box.contact-box--bottom .phone
    {
        display: none;
    }

    .shopping-page-responsive .cart-gift-card .gift-card-item
    {
        margin-bottom: 20px;
        padding: 0;

        border: none;
    }

    .shopping-page-responsive .cart-gift-card .gift-card-item img
    {
        display: block;
        float: none;

        width: 100%;
    }

    .shopping-page-responsive .cart-gift-card .gift-card-item .content
    {
        margin: 0;
        padding: 0;
    }

    .shopping-page-responsive .cart-gift-card .gift-card-item .content.content--bottom .c_title,
    .shopping-page-responsive .cart-gift-card .gift-card-item .content.content--bottom .c_subtitle
    {
        display: none;
    }

    .shopping-page-responsive .page-subtitle
    {
        display: none;
    }

    .shopping-page-responsive .offering .grid.span3
    {
        margin: 0;
    }

    .shopping-page-responsive .offering .grid.span3 .span
    {
        width: 100%;
        margin: 0;

        border: none;
    }

    .shopping-page-responsive .offering .grid.span3 table
    {
        display: block;

        margin: 0;
    }

    .shopping-page-responsive .offering .grid.span3 table tbody
    {
        display: flex;

        flex-wrap: wrap;
    }

    .shopping-page-responsive .offering .grid.span3 table tr
    {
        display: flex;

        max-width: 50%;
        padding: 0 0 0 30px;

        flex: 0 0 50%;
        flex-wrap: nowrap;
        align-items: center;
    }

    .shopping-page-responsive .offering .grid.span3 table tr.odd
    {
        padding: 0 30px 0 0;

        background: none;
    }

    .shopping-page-responsive .offering .grid.span3 table td
    {
        max-width: 50%;
        padding-right: 0;
        padding-left: 0;

        text-align: center;

        flex: 0 0 50%;
    }

    .order-form-responsive
    {
        padding-top: 60px;
    }

    .order-form-responsive .title
    {
        font-size: 70px;
    }

    .order-form-responsive .subtitle
    {
        display: none;
    }

    .order-form-responsive .order-steps
    {
        display: none;
    }

    .order-form-responsive .contact-box
    {
        margin-bottom: 50px;
    }

    .order-form-responsive .contact-box .label
    {
        font-size: 40px;
    }

    .order-form-responsive .order-fieldset.dual ._left,
    .order-form-responsive .order-fieldset.dual ._right
    {
        width: 100%;
        padding: 0;
    }

    .order-form-responsive .order-fieldset.dual ._right
    {
        margin-top: 35px;
    }

    .order-form-responsive .order-fieldset ._subtitle ._subtitle-text
    {
        display: none;
    }

    .order-form-responsive .order-fieldset fieldset
    {
        width: 100%;
        padding: 0;
    }

    .order-form-responsive .order-fieldset fieldset ._fielditem
    {
        padding-left: 160px;
    }

    .order-form-responsive .order-fieldset fieldset ._fielditem > label
    {
        margin-right: -140px;
    }

    .order-form-responsive .fieldset-payments
    {
        position: relative;

        width: 100%;

        transform: none;
    }

    .order-form-responsive .order-fieldset-info
    {
        padding-left: 0;

        text-align: center;
    }

    .order-form-responsive ._payments-info
    {
        padding-right: 0;
    }

    .order-form-responsive ._payments-info .payments-total
    {
        display: none;
    }

    .order-form-responsive .order-table
    {
        table-layout: auto;
    }

    .order-form-responsive .order-table td:first-child
    {
        width: 30%;
    }

    .order-form-responsive .order-table td:last-child
    {
        width: 70%;
    }

    .order-form-responsive .thank-you ._order
    {
        width: 400px;
        max-width: 100%;
    }

    .clubs-responsive #main-slider .title
    {
        font-size: 70px;
    }

    .clubs-responsive .our-wine-club
    {
        padding-bottom: 50px;
    }

    .clubs-responsive .our-wine-club .title
    {
        font-size: 70px;
    }

    .clubs-responsive .our-wine-club .subtitle
    {
        font-size: 16px;
        line-height: 20px;
    }

    .clubs-responsive .club-list
    {
        font-size: 16px;
        line-height: 20px;
    }

    .clubs-responsive .clubs-list-wrap
    {
        max-width: none;
        padding: 0;
    }

    .clubs-responsive .clubs-list
    {
        padding-right: 0;
        padding-left: 0;

        border-right: none;
        border-left: none;
    }

    .clubs-responsive .clubs-list-item .item-title span
    {
        display: block;

        margin-bottom: 8px;
    }

    .clubs-responsive .clubs-list-item .item-title i
    {
        display: none;
    }

    .clubs-responsive .clubs-list-item .item-text
    {
        font-size: 16px;
    }

    .events-responsive #main-slider .title
    {
        font-size: 70px;
    }

    .events-responsive .private-events .bouble-header
    {
        position: absolute;
        bottom: 100%;
        left: 0;

        width: 100%;
        height: 4px;
        margin-top: 0;

        background-size: contain;
    }

    .events-responsive .private-events .bouble-header img
    {
        width: 800px;
        max-width: none;
    }

    .events-responsive .private-events .title
    {
        font-size: 24px;
        line-height: 28px;
    }

    .events-responsive .private-events .text
    {
        font-size: 16px;
        line-height: 20px;
    }

    .events-responsive .events-list-title
    {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .events-responsive .events-list-title .title
    {
        font-size: 60px;
    }

    .events-responsive .events-list-wrap .inner
    {
        max-width: none;
        padding: 0;
    }

    .events-responsive .events-list-wrap .list-item + .list-item
    {
        border-top: 2px solid #fff;
    }

    .events-responsive .events-list-wrap .list-item-body
    {
        display: none;
    }

    .events-responsive .events-list-wrap .item-image
    {
        margin-bottom: 30px;
    }

    .events-responsive .events-list-wrap .item-image
    {
        width: 100%;
    }

    .events-responsive .events-list-wrap .item-title
    {
        display: none;
    }

    .events-responsive .events-contacts .contact-box
    {
        font-size: 18px;
    }
}

@media screen and (max-width: 997px)
{
    .app-mobile-hidden
    {
        display: none;
    }

    header
    {
        position: -webkit-sticky;
        position:         sticky;
        top: 0;
    }

    .drinks-box .sort a
    {
        width: 100px;
    }

    .drinks-box .sort a .spr-view
    {
        line-height: 100px;
    }

    .drinks-box .sort a + a
    {
        margin-left: 20px;
    }

    .quote .quote-icon
    {
        transform: scale(.5);
        transform-origin: 0 50%;
    }

    .order-fieldset ._subtitle
    {
        margin-right: 0;
        margin-left: 0;
    }

    .order-fieldset fieldset
    {
        width: 100%;
    }

    .order-fieldset fieldset .absolute-area
    {
        position: relative;
        left: 0;

        width: 100%;
        margin: 30px 0 0 0;
    }

    .order-fieldset ._fielditem
    {
        padding: 0;
    }

    .order-fieldset ._fielditem > label
    {
        position: relative;
        right: 0;

        display: block;

        width: 100%;
        margin: 0 0 6px;

        text-align: left;
    }

    .order-fieldset ._fielditem.important > label:after
    {
        font-family: 'clarendon medium';
        font-size: 18px;

        margin-left: 6px;

        content: '*';

        color: #ed1b2e;
    }

    .our-wine-card .our-wine-item
    {
        display: flex;
        flex-direction: column-reverse;
    }

    .our-wine-card .our-wine-item .content
    {
        padding: 0 0 30px;
    }

    .our-wine-card .our-wine-item .content .c_title
    {
        margin-top: 40px;
    }

    .our-wine-card .our-wine-item .our-wine-club
    {
        float: none;

        margin: 0;
    }

    .our-wine-card .our-wine-item .our-wine-club .bg
    {
        width: 100%;
    }

    .section-event
    {
        background-size: 30%;
    }

    .section-event .app-section-content
    {
        max-width: 700px;
    }

    .app-drawer__scroll
    {
        -webkit-overflow-scrolling: touch;
    }

    .satisfaction-modal [class*='swiper-button-']
    {
        display: none;
    }

    .r_contact-hours .r_contact-hours-box .r_title
    {
        display: flex;
    }

    .r_contact-hours .r_contact-hours-box .r_title:after
    {
        display: block;

        min-width: 0;
        margin: 0 8px;

        content: '';
        transform: translateY(-6px);

        border-bottom: 1px dashed rgba(175, 149, 129, .69);

        flex: 1;
    }

    .modal-window
    {
        max-width: 100%;
    }

    .modal-window .modal-inner
    {
        padding-right: 30px;
        padding-left: 30px;
    }

    .modal-fade
    {
        z-index: 100;

        overflow-y: auto;
    }

    .modal-fade table td
    {
        padding-right: 18px;
        padding-left: 18px;
    }

    .sign-up .field-item
    {
        padding-left: 0;
    }

    .sign-up .field-item label
    {
        position: relative;

        display: block;

        width: 100%;
        margin-bottom: 4px;

        text-align: left;
    }

    .sign-up .field-item input[type='text']
    {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 767px)
{
    .hidden-xs-max
    {
        display: none !important;
    }

    .calendar-responsive #main-slider .title
    {
        font-size: 40px;
    }

    .calendar-responsive #main-slider .image-box img
    {
        height: 220px;
    }

    .calendar-responsive .table-modal .modal-inner
    {
        padding: 60px 25px 40px;
    }

    .calendar-responsive .table-modal .title
    {
        font-size: 18px;
        line-height: 22px;
    }

    .calendar-responsive .table-modal .content
    {
        padding-top: 0;
        padding-bottom: 0;
    }

    .calendar-responsive .table-modal .content p + p
    {
        padding-top: 0;
    }

    .calendar-responsive .table-modal .offering-price
    {
        font-size: 32px;
        line-height: 34px;

        margin-bottom: 20px;
    }

    .calendar-responsive .table-modal .offering-price span
    {
        white-space: nowrap;
    }

    .calendar-responsive .table-modal .bottom-info
    {
        font-size: 18px;
        line-height: 22px;
    }

    .shopping-page-responsive
    {
        padding-top: 40px;
    }

    .shopping-page-responsive .title
    {
        font-size: 40px;
    }

    .shopping-page-responsive .contact-box
    {
        font-size: 18px;

        display: block;
    }

    .shopping-page-responsive .contact-box .label
    {
        font-size: 34px;

        display: block;

        margin: 0;
    }

    .shopping-page-responsive .contact-box .phone
    {
        margin: 10px 0 0;
    }

    .shopping-page-responsive .offering .grid.span3 table tr
    {
        padding: 0 0 0 10px;
    }

    .shopping-page-responsive .offering .grid.span3 table tr.odd
    {
        padding: 0 10px 0 0;
    }

    .shopping-page-responsive .offering .grid.span3 table td:first-child
    {
        max-width: 40%;

        flex: 0 0 40%;
    }

    .shopping-page-responsive .offering .grid.span3 table td:last-child
    {
        max-width: 60%;

        flex: 0 0 60%;
    }

    .order-fieldset ._title span
    {
        font-size: 16px;

        padding-right: 10px;
        padding-left: 10px;

        letter-spacing: 1px;
    }

    .order-form-responsive
    {
        padding-top: 40px;
    }

    .order-form-responsive .title
    {
        font-size: 40px;
        line-height: 50px;
    }

    .order-form-responsive .contact-box
    {
        font-size: 18px;

        display: block;
    }

    .order-form-responsive .contact-box .label
    {
        font-size: 34px;

        display: block;

        margin: 0;
    }

    .order-form-responsive .contact-box .phone
    {
        margin: 10px 0 0;
    }

    .order-form-responsive .order-fieldset fieldset ._fielditem
    {
        padding-left: 0;
    }

    .order-form-responsive .order-fieldset fieldset ._fielditem > label
    {
        display: none;
    }

    .order-form-responsive .order-fieldset fieldset input:-moz-placeholder
    {
        font-family: 'clarendon';

        opacity: 1;
        color: #4f2d25;
    }

    .order-form-responsive .order-fieldset fieldset input::-moz-placeholder
    {
        font-family: 'clarendon';

        opacity: 1;
        color: #4f2d25;
    }

    .order-form-responsive .order-fieldset fieldset input:-ms-input-placeholder
    {
        font-family: 'clarendon';

        color: #4f2d25;
    }

    .order-form-responsive .order-fieldset fieldset input::-webkit-input-placeholder
    {
        font-family: 'clarendon';

        color: #4f2d25;
    }

    .order-form-responsive .order-fieldset-bottom
    {
        text-align: center;
    }

    .order-form-responsive .order-fieldset-bottom.to-right
    {
        text-align: right;
    }

    .order-form-responsive .order-fieldset-bottom .button-notice
    {
        position: relative;
        top: auto;
        right: auto;

        margin: 20px 0 0;
    }

    .order-form-responsive ._payments-info .grid .span
    {
        float: none;

        width: 100%;
    }

    .order-form-responsive ._payments-info .grid .span + .span
    {
        margin-top: 30px;
    }

    .order-form-responsive ._payments-info .grid .span .__label
    {
        padding: 0;
    }

    .order-form-responsive ._total .order-total
    {
        font-size: 24px;
    }

    .order-form-responsive ._total .divider
    {
        font-size: 24px;
    }

    .order-form-responsive ._total ._verified
    {
        position: relative;
        top: auto;
        right: auto;
        left: auto;

        margin: 20px 0 0;
    }

    .order-form-responsive .order-table tr
    {
        display: block;

        border-top: 1px solid #d7d7d7;
    }

    .order-form-responsive .order-table td
    {
        border: none;
    }

    .order-form-responsive .order-table td:first-child
    {
        display: block;

        width: 100%;
        margin-bottom: 20px;
        padding: 30px 0 0;
    }

    .order-form-responsive .order-table td:last-child
    {
        display: block;

        width: 100%;
        padding: 0 0 30px;
    }

    .order-form-responsive .order-table td ._sb
    {
        display: none;
    }

    .order-form-responsive .order-table td ._sb + p
    {
        padding-top: 0;
    }

    .clubs-responsive #main-slider .title
    {
        font-size: 40px;
        line-height: 50px;
    }

    .clubs-responsive #main-slider .image-box img
    {
        height: 220px;
    }

    .clubs-responsive .our-wine-club .title
    {
        font-size: 40px;
        line-height: 50px;
    }

    .clubs-responsive .club-list .club-list-title
    {
        font-size: 24px;
        line-height: 30px;
    }

    .events-responsive #main-slider .title
    {
        font-size: 40px;
        line-height: 50px;
    }

    .events-responsive #main-slider .image-box img
    {
        height: 220px;
    }

    .events-responsive .events-list-title .title
    {
        font-size: 30px;
        line-height: 40px;
    }

    .events-responsive .events-list-wrap .list-item-head
    {
        font-size: 18px;
        line-height: 22px;
    }

    .events-responsive .events-contacts
    {
        padding-top: 40px;
    }
}

@media (max-width: 459px)
{
    .hidden-mob-max
    {
        display: none !important;
    }
}